import { Component } from '@angular/core';
import { PhcUrl } from '../../../../app/models/interfaces/PhcData';
import { getPHCUrls } from '../../../../app/utils/functions';

@Component({
  selector: 'PW1-no-goals',
  templateUrl: './no-goals.component.html',
  styleUrls: ['./no-goals.component.scss']
})
export class NoGoalsComponent  {

  urls: PhcUrl = {}

  constructor(){
    this.urls = getPHCUrls()
  }

}
