<vg-vgn-nav app-id="PW1">
  <div class="pw1-container">
    <c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>
    <personal-performance-drawer #pageHelpDrawer [position]="pageHelpDrawerPosition" [dataSource]="drawerHelpContentUrl"
      [ContingencyDataObject]="contingencyHelpData">
      <div class="drawer-main-content" [ngClass]="{'reduced': isPageHelpVisible}" tabindex="0" role="tabpanel"
        drawer-main-content>
        <div class="header secure-site-header--custom" *ngIf="!router.url.includes('target')">
          <PW1-secure-site-header-container [isFocusPanelOpen]="isFPOpen"></PW1-secure-site-header-container>
        </div>
        <div class="pw1-container__content">
          <div *ngIf="!router.url.includes('portfolio-health-check') && ((isCBFailure | async) === false)">
            <ss-panel #focusPanelDrawerContainer></ss-panel>
          </div>
          <div id="pw-main" class="ss-panel__push-area">
            <div class="modernized-wrapper" *ngIf="!router.url.includes('target')">
              <div inner>
                <ss-lib-secondary-nav [activeTabItem]="'portfolio_watch'"
                  [displayDownloadCenterLink]="false"></ss-lib-secondary-nav>
              </div>
              <PW1-app-header [displayName]="customDisplayName" *ngIf="shouldRenderHeader() && ((isCBFailure | async) === false)"></PW1-app-header>
            </div>
            <div class="informationBox modernized-wrapper" *ngIf="isLoading | async; else isFailed" #content="pwContent"
              pwContent>
              <c11n-spinner [size]="'large'" [spinnerStatus]="content['PWOverview_LoadingAccountInfo']"></c11n-spinner>
              <p id="loadingText">{{ content['PWOverview_LoadingAccountInfo'] }}</p>
            </div>
            <ng-template #isFailed>
              <div class="modernized-wrapper pw1-failure-banner-wrapper"
                *ngIf="(isFailure | async) || (isCBFailure | async); else isLoaded"
                aria-label="Account information has failed to load">
                <div class="failure-box">
                  <failure-card class="failure-page-modernized"></failure-card>
                </div>
              </div>
            </ng-template>
            <ng-template #isLoaded>
              <div #content="pwContent" pwContent>
                <div class="modernized-wrapper" role="main" aria-label="Account information has loaded" [ngClass]="{
                    'modernized-tester-tool-mode':
                      router.url === '/tester-tool' || router.url.startsWith('/hypothetical'),
                    'target-mix-mode': router.url.includes('target')
                  }">
                  <div *ngIf="
                      (showPWHeader | async) &&
                      !(
                        router.url === '/tester-tool' ||
                        router.url.includes('/domestic-vs-international') ||
                        router.url.includes('target')
                      )
                    ">
                    <PW1-alert-container *ngIf="!router.url.includes('portfolio-health-check')"></PW1-alert-container>
                    <rounding-disclaimer></rounding-disclaimer>
                  </div>
                  <div class="pta-wrapper" *ngIf="!router.url.includes('portfolio-health-check')">Personal Trust
                    accounts are not included in these charts.</div>
                  <router-outlet></router-outlet>
                  <div *ngIf="isTargetAssetMixPage" class="container">
                    <PW1-target-mix-disclaimer></PW1-target-mix-disclaimer>
                  </div>
                </div>
              </div>
              <div class="print-view">
                <print-view></print-view>
              </div>
            </ng-template>
          </div>
        </div>
        <page-help (drawerStateChange)="onDrawerStateChange($event)" #pageHelpDrawerComponent></page-help>
      </div>
    </personal-performance-drawer>
    <PW1-constellation-icon></PW1-constellation-icon>
  </div>
</vg-vgn-nav>