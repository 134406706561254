<div class="flex-column main">
  <div class="flex-row main__content">
    <div class="main__gauge-side">
      <phc-gauge-card [percent]="data.diversifiedLevel" [text]="data.diversifiedLevelText"
        aria-label="Gauge card showing diversification level"></phc-gauge-card>
    </div>
    <div class="flex-column main__analysis-side">
      <div class="flex-column main__analysis-side--header">
        <h2 class="main__analysis-side--title">Analysis for retirement goal</h2>
        <p class="main__analysis-side--title-description">
          This analysis measures your portfolio’s diversification—a key factor to consider for
          long-term investing success. We review your Goal Planner inputs alongside three portfolio
          factors to assess how aligned you are with your asset allocation targets and suggest ways
          you could further diversify if you’d like.
        </p>
      </div>
      <div>
        <h3 class="flex-row main__analysis-side--title-clickable main__analysis-side--title-clickable-h3">
          Portfolio factors
          <c11n-icon-button [labelText]="'Help Button'" [variant]="'ghost'" [iconName]="'help'" [size]="'small'"
            aria-label="Help button" (clickEvent)="openPageHelpDrawer('portfolioFactor')"></c11n-icon-button>
        </h3>
        <div class="main__analysis-side--factors">
          <phc-factor-line
            title="Asset mix"
            description="Having a balanced asset mix can help align your portfolio to your goal, time horizon, and risk tolerance."
            [factorStatus]="data.portfolioFactors.assetMix.aligned"
            aria-label="Asset mix factor line"
          ></phc-factor-line>
          <phc-factor-line
            title="Global stock exposure"
            description="Owning international and domestic stock increases diversification while often reducing risk and volatility."
            [factorStatus]="data.portfolioFactors.domesticStock.aligned"
            aria-label="Global stock exposure factor line"
          ></phc-factor-line>
          <phc-factor-line
            title="Stock concentration"
            description="Concentrating your investments in one security ties your risk to one company’s ups and downs."
            [factorStatus]="data.portfolioFactors.singleStock.aligned"
            aria-label="Single-stock concentration factor line"></phc-factor-line>
        </div>
      </div>
      <div class="flex-row main__analysis-side--links">
        <c11n-button
          [ariaLabelText]="'Review your portfolio'"
          [href]="urls.rebalancePortfolio"
          [iconName]="'link-right'"
          [iconPosition]="'trailing'"
          [labelText]="'Review your portfolio'"
          [size]="'small'"
        >
        </c11n-button>
        <p class="c11n-text-md flex-row main__rebalance-text--clickable"
        (click)="openPageHelpDrawer('learnAboutMethodology')">
          <a
            c11n-link
            [iconPosition]="'leading'"
            [variant]="'secondary-independent'"
            aria-label="Learn more about our methodology"
          >
          Learn more about our methodology
          </a>
        </p>
      </div>
    </div>
  </div>
  <phc-disclosure-box [tag]="'portfolio'"></phc-disclosure-box>
</div>