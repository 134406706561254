import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment'
import { BehaviorSubject, Observable } from "rxjs";
import { PhcData } from "src/app/models/interfaces/PhcData";
import { LoggerService } from "../logger/logger.service";
import { LoggerCode } from '../../../models/logger'
import { ChannelBrokerService } from "./channel-broker.service";

@Injectable({
    providedIn: 'root',
})
export class Pw3ApiService {

    isLoading = new BehaviorSubject<boolean>(true);
    private showPWHeader = new BehaviorSubject(true);
    private isFailure = new BehaviorSubject(false);
    private chartDataPayload = new BehaviorSubject<PhcData>({diversifiedLevel: null, diversifiedLevelText: null, isEligible: null, portfolioFactors: null});


    constructor(private loggerService: LoggerService, private cbService: ChannelBrokerService) { 
        const thiz = this
        this.cbService.getisEligible().subscribe({
            next(isEligible) {
                if (isEligible === null) return
                if (!isEligible) return
                thiz.getPhcData()
            },
            error(err) {
                console.error(err)
                thiz.isFailure.next(true)
                thiz.isLoading.next(false)
            },
        })
    }

    async getPhcData() {

        this.isLoading.next(true);
        this.isFailure.next(false);

        try {
            const startTime = new Date().getTime();

            const response = await fetch( `${environment.PW3_API}/phc-data`, {
                method: 'GET',
                credentials: 'include',
                
              });

              if (!response.ok) {
                throw await response.json();
              }
          
            const data: PhcData = await response.json();

            this.isLoading.next(false);
            this.isFailure.next(false);
            this.chartDataPayload.next(data);
            const endTime = new Date().getTime();
            const totalTime = endTime - startTime;
            this.loggerService.info(
                {
                message:
                    'Time taken to get total response time and display PHC chart Data: ' +
                    totalTime +
                    ' milliseconds.',
                },
                LoggerCode.CHART_DATA_RESPONSE_TIME,
            );
        } catch (error) {
            this.isLoading.next(false);
            this.isFailure.next(true);
            this.loggerService.logChartDataError(error);
            this.chartDataPayload.error(error)
        }
    }



    getLoading(): Observable<boolean> {
        return this.isLoading.asObservable();
    }

    getFailure(): Observable<boolean> {
        return this.isFailure.asObservable();
    }

    setFailure(value: boolean) {
        return this.isFailure.next(value);
    }

    setLoading(value: boolean) {
        return this.isLoading.next(value);
    }

    setShowPWHeader(value: boolean) {
        return this.showPWHeader.next(value);
    }

    getShowPWHeader(): Observable<boolean> {
        return this.showPWHeader.asObservable();
    }

    getDataPayload(): Observable<PhcData> {
        return this.chartDataPayload.asObservable();
    }
}