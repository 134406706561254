import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'phc-aligned-card',
  templateUrl: './aligned-card.component.html',
  styleUrls: ['./aligned-card.component.scss']
})
export class AlignedCardComponent implements OnInit {

  @Input() isAligned = false;
  
    cardColor: string;
  
    ngOnInit(): void {
      this.setCardColor(this.isAligned)
    }
  
    setCardColor(isAligned: boolean) {
      if (isAligned) {
        this.cardColor = 'turquoise'
      } else {
        this.cardColor = 'yellow'
      }
    }

}
