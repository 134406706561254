import { Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'phc-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent  {

  @Input() flexDirection: 'row' | 'row-reverse' = 'row-reverse' 
  isOpen = false;

  constructor(private renderer: Renderer2, private el: ElementRef) { }


  toggleAccordion(){    
    this.isOpen = !this.isOpen;
    const c11nAccordion = this.el.nativeElement.querySelector('.c11n-accordion');
    const button = this.el.nativeElement.querySelector('.c11n-accordion__trigger');
    if (this.isOpen) {
      this.renderer.addClass(c11nAccordion, 'c11n-accordion--expanded');
      this.renderer.setAttribute(button, 'aria-expanded', 'true')
      this.renderer.setAttribute(button, 'title', 'show less')
    } else {
      this.renderer.removeClass(c11nAccordion, 'c11n-accordion--expanded');
      this.renderer.setAttribute(button, 'aria-expanded', 'false')
      this.renderer.setAttribute(button, 'title', 'show more')
    }
  }
}
