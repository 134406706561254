import { Component } from '@angular/core';
import { PhcUrl } from '../../../../../app/models/interfaces/PhcData';
import { getPHCUrls } from '../../../../../app/utils/functions';

@Component({
  selector: 'phc-sell-accordion',
  templateUrl: './sell-accordion.component.html',
  styleUrls: ['./sell-accordion.component.scss']
})
export class SellAccordionComponent {

  urls: PhcUrl = {}

  constructor() {
    this.urls = getPHCUrls()
  }


}
