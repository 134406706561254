import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'phc-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
})
export class GaugeComponent implements OnInit {
  @Input() percent: number;
  @Input() text;
  @Input() changeColorRange: number = 50;
  gaugePercent: number;
  words: string[];

  async ngOnInit() {
    this.words = this.text.split(' ');
    for (let percent = 0; percent <= this.percent; percent++) {
      await this.delay(30);
      this.gaugePercent = percent;
    }
  }

  async delay(milliseconds: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }

  getArrowTransform(): string {
    // Calculate the rotation based on the percentage
    const rotation = (this.gaugePercent / 100) * 180 + 180;
    return `rotate(${rotation} 152 152)`;
  }

  getArrowColor(): string {
    // Change color based on the percentage
    return this.gaugePercent >= this.changeColorRange ? '#1A9E97' : '#FFAD00';
  }
}
