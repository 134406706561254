import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageHelpService {
    public currentDrawerStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public currentDrawerType$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    togglePageHelpDrawer() {
        this.currentDrawerStatus$.next(!this.currentDrawerStatus$.value);
    }

    setCurrentDrawerStatus(status: boolean) {
        this.currentDrawerStatus$.next(status);
    }

    setCurrentDrawerType(type: string) {
        this.currentDrawerType$.next(type);
    }
}