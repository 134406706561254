import { Component, Input } from '@angular/core';
import { PageHelpItem } from '../page-help-content/phc-page-help';

@Component({
  selector: 'page-help-parser',
  templateUrl: './page-help-parser.component.html',
  styleUrls: ['./page-help-parser.component.scss']
})
export class PageHelpParserComponent {
  @Input() item: PageHelpItem

  parseLinks(item: PageHelpItem) {
    return item.link.reduce((acc, link) => {
      return acc.replace(link.key, ` <a class="c11n-link c11n-link--secondary" aria-label="${link.ariaLabel}" href="${link.link}">
        <span class="c11n-link__content">${link.content}</span>
        <span class="c11n-link__content">
          <svg class="c11n--small">
            <use xlink:href="#-small"></use>
          </svg>
        </span>
      </a>`);
    }, item.content as string);
  }

}
