<div #content="pwContent" pwContent>
  <c11n-banner
    [headingText]="content[headerTag] | getFromAEM"
    [iconName]="'announcement'"
    [size]="'medium'"
    [variant]="'basic'"
    [hasCloseControl]="hasCloseControl"
  >
    <span
      class="c11n-text-md--crop"
      *ngIf="!isEnabledAEMGatekeeper"
      [innerHtml]="
        content[contentTag] | getFromAEM | aemlink : GetLinkText(content[linkTag] | getFromAEM) : GetLinkUrl(content[linkTag] | getFromAEM)
      "
    ></span>
    <div *ngIf="isEnabledAEMGatekeeper" class="c11n-text-md--crop">
      <p [innerHtml]="content['PWCosts_Disclaimer_1'] | getFromAEM"></p>
      <p class="c11n-text-md c11n-link--reinforced">
        {{(content['PWCosts_Disclaimer_2'] | getFromAEM).substring(6).substring(0, 6)}} 
        <a c11n-link href="https://investor.vanguard.com" [variant]="'primary-reinforced'" [noWrap]="'true'">
          {{content['PWCosts_Disclaimer_2_Link'] | getFromAEM}}
        </a>
        {{(content['PWCosts_Disclaimer_2'] | getFromAEM).substring(40).substring(0, 218)}}
      </p>
      <p [innerHtml]="content['PWCosts_Disclaimer_3'] | getFromAEM"></p>
      <p [innerHtml]="content['PWCosts_Disclaimer_4'] | getFromAEM"></p>
      <p [innerHtml]="content['PWCosts_Disclaimer_5'] | getFromAEM"></p>
      <p [innerHtml]="content['PWCosts_Disclaimer_6'] | getFromAEM"></p>
      <p [innerHtml]="content['PWCosts_Disclaimer_7'] | getFromAEM"></p>
      <p [innerHtml]="content['PWCosts_Disclaimer_8'] | getFromAEM"></p>
      <p [innerHtml]="content['PWCosts_Disclaimer_9'] | getFromAEM"></p>
      <p [innerHtml]="content['PWCosts_Disclaimer_10'] | getFromAEM"></p>
    </div>
  </c11n-banner>
</div>
