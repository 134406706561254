import { production, external } from './environment.production';

export const environment = {
  ...production,
  ...external,
  isExternal: 'true',
  HOST: 'personal.vanguard.com',
  chartDataUrlEcs:
    external.RegionlessHostUrl +
    '/pw2-portfolio-watch-webservice/portfoliowatch/chart-data?poid=VG-CLIENT-POID&viewID=VIEWID&accountGroup=ACCOUNTGROUP',
  securityDataUrlEcs:
    external.RegionlessHostUrl + '/pw2-portfolio-watch-webservice/portfoliowatch/valid-security?',
  hypotheticalDataUrlEcs:
    external.RegionlessHostUrl +
    '/pw2-portfolio-watch-webservice/portfoliowatch/hypothetical-data?poid=VG-CLIENT-POID&viewID=VIEWID&accountGroup=ACCOUNTGROUP',
  targetMixRiskReturnUrl:
    external.RegionlessHostUrl +
    '/pw2-portfolio-watch-webservice/portfoliowatch/target-risk-return',
  GraphQLUrl: 'https://apps.ecs.retp.c1.vanguard.com/xs1-secure-site-consumer-api/graphql',
  saveTargetMixUrl:
    external.RegionlessHostUrl + "/ag3-account-grouping-consumer-api/target-allocation/create",
  updateTargetMixUrl:
    external.RegionlessHostUrl + "/ag3-account-grouping-consumer-api/target-allocation/edit",
  LOGGER_URL: external.RegionlessHostUrl + '/pw2-portfolio-watch-webservice/log',
  AEM: 'https://pi.aemgraphql.vanguard.com/graphql/execute.json/portfolio-analysis/portfolio-watch-combined-content',
  AEMContentUrl:
    'https://personal1.vanguard.com/content/retail-headless/portfolio-analysis/portfolio-analysis/content.model.json.json',
  AEMHelpContentUrl:
    'https://personal1.vanguard.com/content/retail-headless/portfolio-analysis/portfolio-analysis/portfolio-analysis-help.model.json.json',
  deployment: 'production',
  CONSUMER_API: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/',
  LOGON_URL: 'https://logon.vanguard.com/logon',
  ADOBE_TARGET_ANTIFLICKER_JS: 'https://corp-at2.web.vanguard.com/antiflicker_v1/antiflicker.min.js',
  TEALEAF_LIB: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/tealeaf',
  GATEKEEPER_PW2_API:
    external.RegionlessHostUrl +
    '/pw2-portfolio-watch-webservice/portfoliowatch/toggle-switch?toggleId={{toggleid}}',
  ModernizedManageGroupApp: 'https://portfoliowatch-views.web.vanguard.com/managegroups',
  ModernizedCustomizeView: 'https://customize-view.cdn.web.vanguard.com/',
  c3Source: "https://mc3-static-assets.web.vanguard.com/vg-ccc-serverless/latest/vg-ccc-serverless.js",
  c3Config: `const cccConfig = {
    site: 'retailExternal' ,
    rte: 'PROD',
    app: 'PW1',
    concerns: 'al,adl,it,adv4,cob',
    appConfig: {
      appdynamicskey: "AD-AAB-ABU-EPX",
    }
  };

  window.c3bundle && c3bundle.loadC3Bundle(cccConfig);`,
  VGN_HEADER_FOOTER_JS: 'https://n4v.web.vanguard.com/secure-site-components.js',
  VGN_HEADER_FOOTER_STYLE: 'https://n4v.web.vanguard.com/nav-preload.css',
  FOCUS_PANEL_ELEMENT_JS: 'https://panel.cdn.web.vanguard.com/secure-site-panel/secure-site-panel.esm.js',
  FOCUS_PANEL_GET_SELECTED_GROUP: 'https://personal1.vanguard.com/ag3-account-grouping-consumer-api/getSelectedGroup',
  PW3_API: external.PhcRegionlessHostUrl + "/pw3-portfolio-health-check-api/api"
};
