import { Component } from '@angular/core';
import { Pw3ApiService } from '../../services/portofolio-health-check/pw3-api.service';
import { PhcData, PhcUrl } from '../../../app/models/interfaces/PhcData';
import { getPHCUrls } from '../../../app/utils/functions';
import { PageHelpService } from 'src/app/components/page-help/page-help.component.service';

@Component({
  selector: 'PW1-portfolio-health-check-page',
  templateUrl: './portfolio-health-check-page.component.html',
  styleUrls: ['./portfolio-health-check-page.component.scss']
})
export class PortfolioHealthCheckPageComponent {

  data: PhcData
  urls: PhcUrl = {}

  constructor(
    private pw3Service: Pw3ApiService,
    public readonly pageHelpService: PageHelpService,
  ) {
    this.pw3Service.getDataPayload().subscribe((data) => {
      this.data = data;
    })
    this.urls = getPHCUrls()
  }

  openPageHelpDrawer(type: string) {
    this.pageHelpService.setCurrentDrawerType(type);
    this.pageHelpService.togglePageHelpDrawer();
  }
}
