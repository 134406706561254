import { Component } from '@angular/core';

@Component({
  selector: 'phc-take-action-banner-image-component',
  templateUrl: './take-action-banner.component.html',
  styleUrls: ['./take-action-banner.component.scss']
})
export class TakeActionBannerComponent  {

  

}
