<svg
  width="100%"
  height="100%"
  viewBox="0 0 304 169"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  role="img"
  aria-label="Gauge showing diversification level"
>
  <g>
    <svg
      width="304"
      height="152"
      viewBox="0 0 304 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text text-anchor="middle" x="100" y="74" fill="black" font-size="17px">
        <tspan x="152" dy="1.2em" *ngFor="let word of words">{{ word }}</tspan>
      </text>
      <path
        fill="#E8E9E9"
        d="M27.8935 64.22C10.3253 89.0133 0 119.301 0 152H19C19 123.576 27.9165 97.2344 43.1051 75.62L27.8935 64.22Z M152 0C100.752 0 55.4274 25.3623 27.8935 64.22L43.1051 75.62C67.1671 41.3781 106.97 19 152 19C171.802 19 190.594 23.3276 207.48 31.0887L215.46 13.8406C196.147 4.95513 174.652 0 152 0Z M207.48 31.0887C253.232 52.1164 285 98.3483 285 152H304C304 90.705 267.719 37.8839 215.46 13.8406L207.48 31.0887Z"
      />
      <g [attr.transform]="getArrowTransform()">
        <path
          d="M27.8935 64.22C10.3253 89.0133 0 119.301 0 152H19C19 123.576 27.9165 97.2344 43.1051 75.62L27.8935 64.22Z M152 0C100.752 0 55.4274 25.3623 27.8935 64.22L43.1051 75.62C67.1671 41.3781 106.97 19 152 19C171.802 19 190.594 23.3276 207.48 31.0887L215.46 13.8406C196.147 4.95513 174.652 0 152 0Z M207.48 31.0887C253.232 52.1164 285 98.3483 285 152H304C304 90.705 267.719 37.8839 215.46 13.8406L207.48 31.0887Z"
          [attr.fill]="getArrowColor()"
        />
      </g>
    </svg>
  </g>
  <g [attr.transform]="getArrowTransform()">
    <path
      d="M293.738 150.742C294.957 151.096 295.036 152.793 293.855 153.258L256.258 168.074C255.174 168.501 254.052 167.409 254.395 166.295C255.323 163.278 256.538 158.387 256.332 153.952C256.117 149.326 254.888 144.342 254.069 141.46C253.774 140.425 254.741 139.43 255.775 139.73L293.738 150.742Z"
      fill="#040505"
    />
  </g>
</svg>
