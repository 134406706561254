<ng-container *ngIf="!isAssetMixChart">
    <range-chart [rangeChartData]="actualChartData"></range-chart>
</ng-container>
<ng-container *ngIf="isAssetMixChart">
    <stacked-bar-chart [barChartData]="actualChartData" [isFullWidth]="true" [isPHC]="true"></stacked-bar-chart>
    <stacked-bar-chart [barChartData]="targetChartData" [isFullWidth]="true" [isPHC]="true"></stacked-bar-chart>
</ng-container>

<table class="c11n-table c11n-table--small c11n-table--sticky-th-vertical c11n-table--sticky-th-horizontal" aria-label="XXXX">
    <thead>
      <tr class="c11n-table__tr">
        <th id="asset-type-header" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">Asset Type</th>
        <th id="current-header" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right">Current</th>
        <th id="target-header" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right" *ngIf="isAssetMixChart">Target</th>
        <th id="target-header" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right" *ngIf="!isAssetMixChart">Target range*</th>
        <th id="diff-header" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right" *ngIf="isAssetMixChart">Diff.</th>
      </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="!isAssetMixChart">
            <tr
                *ngFor="let actualChartDataSlice of actualChartData.chartSlices; let myIndex = index"
                class="c11n-table__tr-content"
            >
                <th scope="row" id="asset-type-header" class="c11n-table__thead-th c11n-table__thead-th--align-left c11n-table__thead-td">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0" y="0" width="15" height="15" [attr.fill]="actualChartDataSlice.color" stroke="white"/>
                    </svg>
                    <span>{{ actualChartDataSlice.label }}</span>
                </th>
                <td id="current-header" class="c11n-table__thead-th c11n-table__thead-th--align-right c11n-table__thead-td">
                    {{ actualChartDataSlice.value }}%
                </td>
                <td id="target-header" class="c11n-table__thead-th c11n-table__thead-th--align-right c11n-table__thead-td">
                    <ng-container *ngIf="actualChartDataSlice.range.length">
                        {{actualChartDataSlice.range[0]}}%-{{actualChartDataSlice.range[1]}}%
                    </ng-container>
                    <ng-container *ngIf="!actualChartDataSlice.range.length">
                        -
                    </ng-container>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="isAssetMixChart">
            <tr
                *ngFor="let actualChartDataSlice of actualChartData.chartSlices; let myIndex = index" scope="row"
                class="c11n-table__tr-content"
            >
                <th scope="row" id="asset-type-body" class="c11n-table__thead-th c11n-table__thead-th--align-left c11n-table__thead-td">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0" y="0" width="15" height="15" attr.fill="{{ actualChartDataSlice.color }}" stroke="white"/>
                    </svg>
                    <span>{{ actualChartDataSlice.label }}</span>
                </th>
                <td id="current-body" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right c11n-table__thead-td">
                    {{ actualChartDataSlice.value }}%
                </td>
                <td id="target-body" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right c11n-table__thead-td">
                    {{targetChartData.chartSlices[myIndex].value}}%
                </td>
                <td id="diff-body" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right c11n-table__thead-td">
                    {{ actualChartDataSlice.value - targetChartData.chartSlices[myIndex].value }}%
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>