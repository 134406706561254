type PageHelpItemType = 'htmlTag' | 'card' | 'unorderedList' | 'link' | 'htmlTagWithLink'

type PageHelpItemLink = {
    key: string
    content: string,
    link: string,
    ariaLabel: string
}

export type PageHelpItem = {
    type: PageHelpItemType
    content?: string | PageHelpItemContenCard | string[] | PageHelpItemList
    link?: PageHelpItemLink[]
}

type PageHelpItemList = PageHelpItem[]

export type PageHelpItemContenCard = {
    title: string
    content: PageHelpItem[]
}

export type PageHelpKeys = 'aboutPortfolioWatch' | 'portfolioFactor' | 'learnAboutMethodology'

type PageHelp = {
    [key in PageHelpKeys]: PageHelpItem[]
}

const pageHelp: PageHelp = {
    aboutPortfolioWatch: [
        {
            type: 'htmlTag',
            content: '<h2>About portfolio watch</h2>'
        },
        {
            type: 'htmlTag',
            content: '<p>Portfolio Watch is a tool that helps you assess how diversified your portfolio is using your Goal Planner inputs. It evaluates three factors: the balance of your current asset mix, your exposure to both domestic and international stocks (which helps reduce risk), and any heavy concentration in a single company’s stock. It then compares this information with the targets generated from the Goal Planner and suggests ways you could improve your portfolio diversification.</p>'
        },
        {
            type: 'htmlTag',
            content: '<p>Understanding these elements is essential for aligning with Vanguard’s investment philosophy of balancing risk with the potential for growth.</p>'
        },
        {
            type: 'htmlTag',
            content: '<p class="page-help-note">When analyzing the asset and sub‐asset allocation of securities held in your current portfolio, various asset classification assessments are used based partially on information received from third‐party data providers to categorize these investments. The methodologies used to assess and set the asset classifications of securities vary based on data availability and source.</p>'
        },
        {
            type: 'htmlTag',
            content: '<p class="page-help-note">This tool only assesses certain diversification factors based on asset allocation and sub-asset allocation.  You should carefully consider investment objectives, investment risks, charges, expenses when considering specific investment decisions.</p>'
        },
        {
            type: 'card',
            content: {
                title: 'Diversification',
                content: [
                    {
                        type: 'htmlTag',
                        content: '<p>Diversification is the strategy of spreading your investments across different asset types, sectors, and regions to help reduce risk. It helps ensure that poor performance in one area, doesn’t heavily impact your entire portfolio. As the market changes over time, your asset mix will too. Rebalancing periodically can help maintain your portfolio diversification.</p>'
                    }
                ]
            }
        },
        {
            type: 'card',
            content: {
                title: 'Aligned',
                content: [
                    {
                        type: 'htmlTag',
                        content: '<p>A portfolio factor is considered aligned when your current allocation closely matches the targets generated by your Goal Planner inputs. This alignment can help manage diversification risk and optimize potential returns.</p>'
                    }
                ]
            }
        },
        {
            type: 'card',
            content: {
                title: 'Not aligned',
                content: [
                    {
                        type: 'htmlTag',
                        content: '<p>A portfolio factor is considered not aligned when your current allocation differs from the target generated by your Goal Planner inputs. Misalignment can increase diversification risk and lead to sub optimal outcomes.</p>'
                    }
                ]
            }
        },
        {
            type: 'card',
            content: {
                title: 'Asset mix target',
                content: [
                    {
                        type: 'htmlTag',
                        content: `<p>Your target asset mix is calculated using your Personalized Glide Path, which is based on the information you provide in the Goal Planner, such as your retirement age, how much risk youre comfortable with, and your current age.****</p>`
                    }
                ]
            }
        },
        {
            type: 'card',
            content: {
                title: 'Rebalancing',
                content: [
                    {
                        type: 'htmlTag',
                        content: '<p>Rebalancing refers to making adjustments to your portfolio to align it to your preferred asset mix. As circumstances change over time, you may need to adjust your portfolio. For example, market fluctuations may cause your asset allocation to move outside your comfort zone. Maintaining a balanced portfolio can help reduce risk, improve portfolio diversification, and reach your investment goals.</p>'
                    }
                ]
            }
        },
    ],
    learnAboutMethodology: [
        {
            type: 'htmlTag',
            content: '<h3>Learn more about our methodology</h3>'
        },
        {
            type: 'htmlTag',
            content: '<p>According to Vanguard’s principles for investing success, setting a clear goal and having a diversified mix of investments helps ensure long-term success. This analysis examines the diversification of your portfolio based on three key factors:</p>'
        },
        {
            type: 'unorderedList',
            content: [
                {
                    type: 'htmlTagWithLink',
                    content: '<p><b>Asset mix:</b> Reviews if your current proportion of stocks, bonds, and cash align with your personalized target from your Goal Planner inputs. Having a balanced mix can help align your portfolio to your individual risk tolerance, time horizon, and financial goals. (LINK_1)</p>',
                    link: [
                        {
                            key: 'LINK_1',
                            content: 'Learn more about our glidepath methodology',
                            link: 'https://corporate.vanguard.com/content/dam/corp/research/pdf/Vanguards-Life-Cycle-Investing-Model-VLCM-A-general-portfolio-framework-US-ISGVLCM_032021_online.pdf',
                            ariaLabel: 'Learn more about our glidepath methodology'
                        }
                    ]
                },
                {
                    type: 'htmlTagWithLink',
                    content: '<p><b>Global stock exposure:</b> Reviews how much of your stock portfolio is invested in international markets. Having both domestic and international stocks can help increase returns, reduce risk, and improve stability. (LINK_1)</p>',
                    link: [
                        {
                            key: 'LINK_1',
                            content: 'Review our research on global stock exposure',
                            link: 'https://corporate.vanguard.com/content/corporatesite/us/en/corp/articles/making-case-international-equity-allocations.html',
                            ariaLabel: 'Review our research on global stock exposure'
                        }
                    ]
                },
                {
                    type: 'htmlTag',
                    content: '<p><b>Stock concentration:</b> Reviews how much of your portfolio is invested in one company’s stock. A high concentration of investments in one company ties a lot of your risk to that company’s ups and downs. If that company performs poorly, a large part of your portfolio could be affected.</p>'
                },
            ]
        },
        {
            type: 'htmlTag',
            content: '<p>Based on Vanguard’s research, this analysis can help assess your portfolio’s risk. It’ll let you know ways you can rebalance so you can choose what’s best for you to stay on track toward meeting your goals.</p>'
        },
        {
            type: 'link',
            content: 'LINK_1',
            link: [
                {
                    key: 'LINK_1',
                    content: 'Learn more about our glidepath methodology',
                    link: 'https://corporate.vanguard.com/content/dam/corp/research/pdf/vanguards_principles_for_investing_success.pdf',
                    ariaLabel: 'Learn more about our glidepath methodology'
                }
            ]
        },
    ],
    portfolioFactor: [
        {
            type: 'htmlTag',
            content: '<h2>Portfolio factors</h2>'
        },
        {
            type: 'htmlTag',
            content: '<p>Based on your inputs to Goal Planner, this analysis reviews the following factors: asset mix, global stock exposure, and stock concentration to determine your portfolio diversification.</p>'
        },
        {
            type: 'htmlTag',
            content: '<p>Understanding these elements is essential for aligning with Vanguard’s investment philosophy of balancing risk with the potential for growth.</p>'
        },
        {
            type: 'htmlTag',
            content: '<p class="page-help-note">This tool only assesses certain diversification factors based on asset allocation and sub asset allocation.  You should carefully consider investment objectives, investment risks, charges, and expenses when considering specific investment decisions.</p>'
        },
        {
            type: 'card',
            content: {
                title: 'Asset mix',
                content: [
                    {
                        type: 'htmlTag',
                        content: '<p>Asset mix is how your investments are distributed between asset types. Having a balanced mix can help align your portfolio to your individual risk tolerance, time horizon, and financial goals.</p>'
                    },
                    {
                        type: 'htmlTag',
                        content: '<p>*Brokered CDs are included within the “Bonds” category</p>'
                    }
                ]
            }
        },
        {
            type: 'card',
            content: {
                title: 'Global stock exposure',
                content: [
                    {
                        type: 'htmlTag',
                        content: '<p>Global stock exposure refers to owning stock investments across both domestic (U.S.) and international markets. By diversifying your stocks across global markets, this helps to offset potential losses and can enhance overall portfolio stability if one of these markets performed poorly.</p>'
                    }
                ]
            }
        },
        {
            type: 'card',
            content: {
                title: 'Stock concentration',
                content: [
                    {
                        type: 'htmlTag',
                        content: '<p>Stock concentration reviews how much of your portfolio is invested in one company’s stock. A high concentration of investments in one company ties a lot of your risk to that company’s ups and downs. If that company performs poorly, a large part of your portfolio could be affected.</p>'
                    }
                ]
            }
        }
    ]
}

export default pageHelp;