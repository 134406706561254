<div class="c11n-accordion c11n-accordion--medium c11n-accordion--normal-weight c11n-accordion--leading">
    <h2>
        <button type="button" class="c11n-accordion__trigger c11n-accordion__trigger-medium" aria-expanded="false"
            title="show more" (click)="toggleAccordion()" style="padding: 23px 0px 25px 0px;" >
            <span class="c11n-accordion__trigger-items" style="align-items: center;" [ngStyle]="{'flex-direction': flexDirection}">
                <span class="c11n-accordion__heading-group">
                    <span class="c11n-accordion__heading">
                        <span>
                            <ng-content select="header"></ng-content>
                        </span>
                    </span>
                    <span class="c11n-accordion__content">
                    </span>
                </span>
                <span class="c11n-accordion__icon">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_6711_18931)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M13.6868 3.39751C13.2964 3.00715 12.6636 3.00715 12.2732 3.39751L7.1585 8.5102C7.11161 8.55722 7.04795 8.58364 6.98155 8.58364C6.91515 8.58364 6.85148 8.55722 6.80459 8.5102L1.68989 3.39751C1.29528 3.03911 0.688943 3.0534 0.311638 3.42997C-0.0656667 3.80655 -0.0812354 4.41297 0.276249 4.80842L5.92082 10.4551C6.20208 10.7366 6.58366 10.8947 6.98155 10.8947C7.37944 10.8947 7.76102 10.7366 8.04228 10.4551L13.6868 4.80842C14.0751 4.41829 14.0751 3.78764 13.6868 3.39751Z"
                                fill="#040505" />
                        </g>
                        <defs>
                            <clipPath id="clip0_6711_18931">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
            </span>
        </button>
    </h2>
    <div class="c11n-accordion__body">
        <div class="c11n-accordion__body-content">
            <div class="c11n-accordion-story-content">
                <div class="c11n-text-md--crop c11n-link--reinforced">
                    <ng-content select="content"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>