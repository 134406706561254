import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'phc-aligned-chip',
  templateUrl: './aligned-chip.component.html',
  styleUrls: ['./aligned-chip.component.scss']
})
export class AlignedChipComponent implements OnInit {

  @Input() isAligned = false;

  factorStatusName: string;
  factorStatusColor: string;

  ngOnInit(): void {
    this.setStatusColorAndName(this.isAligned)
  }

  setStatusColorAndName(factorStatus: boolean) {
    if (factorStatus) {
      this.factorStatusColor = 'turquoise'
      this.factorStatusName = 'Aligned'
    } else {
      this.factorStatusColor = 'yellow'
      this.factorStatusName = 'Not Aligned'
    }
  }

}
