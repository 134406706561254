import { AdobeAnalyticsService } from './services/adobe-analytics/adobe-analytics.service';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, SecurityContext, ChangeDetectorRef } from '@angular/core';
import { PortfolioDataService } from './services/portfolio-data-service/portfolio-data.service';
import { Observable, filter, Subject, lastValueFrom } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { WindowRef } from './services/window-ref/window-ref.service';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { LoggerCode } from '../models/logger';
import { LoggerService } from './services/logger/logger.service';
import { targetAssetMixRoutes } from './models/chart-models/target-allocation';
import { DrawerComponent, DrawerTrackerService } from '@vanguard/pfx-components';
import { environment } from 'src/environments/environment';
import { DrawerPageSortMapper } from 'src/config/config.inline-drawer';
import { AemDrawerData } from '@vanguard/pfx-components/lib/components/drawer/drawer-data.interface';
import { default as helpContent } from '../assets/content.help.json';
import { CustomUserModernizationService } from './services/custom-user-modernization/custom-user-modernization.service';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { MFEEventTypes } from '@vanguard/mfe-events';
import { Pw3ApiService } from './services/portofolio-health-check/pw3-api.service';
import { ChannelBrokerService } from './services/portofolio-health-check/channel-broker.service';
import { PhcUrl } from './models/interfaces/PhcData';
import { getPHCUrls } from './utils/functions';
import { PageHelpComponent } from './components/page-help/page-help.component';
import { PageHelpService } from './components/page-help/page-help.component.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  public isLoading: Observable<boolean>;
  public isFailure: Observable<boolean>;
  public isCBFailure: Observable<boolean>;
  public showPWHeader: Observable<boolean>;
  isTargetAssetMixPage: boolean = false;
  @ViewChild('pageHelpDrawer') pageHelpDrawer: DrawerComponent;
  drawerHelpContentUrl: string;
  pageHelpDrawerPosition: string = 'end';
  shouldFocusPanelOpen: boolean = true;
  contingencyHelpData: AemDrawerData = helpContent;
  fpDrawerThreshold: number = 1200;
  isFpDrawerModal: boolean = false;
  fpDrawerWidth: string = '392px';
  focusPanelMFELoaded: boolean = false;
  private readonly _destroy: Subject<void> = new Subject<void>();
  currentUrl: string;
  isPageHelpOpen: boolean = false;
  customDisplayName: string = '';
  isFPOpen: boolean = false;
  readonly CLASSNAME_FOCUS_PANEL_ACTIVE = 'ss-panel--active';
  isPhcPage: boolean = false;
  phcUrls: PhcUrl = {};
  isPageHelpVisible = false;
  @ViewChild('pageHelpDrawerComponent') pageHelpDrawerComponent: PageHelpComponent;
  withoutPageHelpPagesURLs: string[] = [];
  isAccountSelected: boolean = false;

  // eslint-disable-next-line max-params
  constructor(
    private titleService: Title,
    private portfolioDataService: PortfolioDataService,
    public router: Router,
    private windowRef: WindowRef,
    private meta: Meta,
    private loggerService: LoggerService,
    public readonly pageHelpDrawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private customUserModernizationService: CustomUserModernizationService, // do not remove this; it will initialize the user modernization verification.
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private changeDetector: ChangeDetectorRef,
    private pw3ApiService: Pw3ApiService,
    private cbService: ChannelBrokerService,
    public readonly pageHelpService: PageHelpService,
  ) {
    this.phcUrls = getPHCUrls()
    this.withoutPageHelpPagesURLs.push(this.phcUrls.noGoals)
    this.drawerHelpContentUrl = environment.AEMHelpContentUrl;

    this.isPhcPage = window.location.href.includes(this.phcUrls.phc)
    this.isCBFailure = this.cbService.getFailure();

    if (this.isPhcPage) {
      this.isLoading = this.pw3ApiService.getLoading();
      this.isFailure = this.pw3ApiService.getFailure();
      this.showPWHeader = this.pw3ApiService.getShowPWHeader();
    } else {
      this.isLoading = this.portfolioDataService.getLoading();
      this.isFailure = this.portfolioDataService.getFailure();
      this.showPWHeader = this.portfolioDataService.getShowPWHeader();
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.windowRef.nativeWindow?.scrollTo({ top: 0, left: 0 });
        this.loggerService.info(
          {
            message: 'PageLoadSuccess',
            path: this.sanitizer.sanitize(SecurityContext.URL, event.url) || '',
          },
          LoggerCode.PAGE_LOAD,
        );

        this.currentUrl = event.url;

        this.isPhcPage = this.currentUrl.includes(this.phcUrls.phc)

        if (this.isPhcPage) {
          this.isLoading = this.pw3ApiService.getLoading();
          this.isFailure = this.pw3ApiService.getFailure();
          this.showPWHeader = this.pw3ApiService.getShowPWHeader();
        } else {
          this.isLoading = this.portfolioDataService.getLoading();
          this.isFailure = this.portfolioDataService.getFailure();
          this.showPWHeader = this.portfolioDataService.getShowPWHeader();
        }

        if (this.isPhcPage) {
          this.isTargetAssetMixPage = false;
          this.toggleFocusPanel(false);
          return;
        }

        let assetMixRoutes = Object.values(targetAssetMixRoutes) as Array<string>;
        this.isTargetAssetMixPage = assetMixRoutes.includes(event.url);
        if (this.autoCloseDrawer()) {
          if (this.isFPOpen) {
            this.shouldFocusPanelOpen = true;
            this.closeFocusPanel();
          }
        } else {
          if (this.shouldFocusPanelOpen && !this.isPageHelpOpen) {
            this.toggleFocusPanel(true)
          }
        }
      });

    window.addEventListener('load', (event) => {
      this.loggerService.info(
        {
          message: `${this.isPhcPage ? 'Portfolio health check' : 'Portfolio watch'} load duration`,
          duration: event.timeStamp,
        },
        LoggerCode.PORTFOLIO_WATCH_LOADED_DURATION,
      );
    });
  }

  newFocusPanelListener(add: boolean) {
    const focusPanel = document.querySelector('ss-panel');

    if (focusPanel && add) {
      if (MFEEventTypes && MFEEventTypes.Select) {
        focusPanel.addEventListener(MFEEventTypes.Select, (event) => this.getGroupData(event), true);
      }else{
        focusPanel.addEventListener('mfeSelect', (event) => this.getGroupData(event), true);
      }
      focusPanel.addEventListener('mfeLoad', () => {
        this.checkWindowWidth();
        this.focusPanelMFELoaded = true;
      }, true);
      document.body.dispatchEvent(new CustomEvent('ss-panel-visibility', { detail: { open: true } }));
    } else {
      if (MFEEventTypes && MFEEventTypes.Select) {
        focusPanel.removeEventListener(MFEEventTypes.Select, (event) => this.getGroupData(event));
      } else {
        focusPanel.removeEventListener('mfeSelect', (event) => this.getGroupData(event));
      }
    }
  }

  autoCloseDrawer(): boolean {
    const excludeUrls = [
      '/target-mix-custom-view',
      '/tester-tool',
      '/target-mix-about',
      '/target-mix-questionnaire',
      '/suggested-target-asset-mix',
    ];
    if (this.router.url.startsWith('/hypothetical')) {
      excludeUrls.push(this.currentUrl);
    }
    return excludeUrls.includes(this.currentUrl);
  }

  ngOnInit() {
    this.updateSpoid();
    this.setRandomNum();

    // if (!this.isPhcPage) {
    //   this.listenToBodyClassChanges();
    // }
    this.isLoading.subscribe(isLoading => {
      if (!isLoading) {
        window.dispatchEvent(new Event('resize'));
      }
    })
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Update the title based on the current route
      const newTitle = this.getTitleForRoute(event.urlAfterRedirects);
      this.titleService.setTitle(newTitle);
    });
  }

  ngAfterViewInit() {
    this.pageHelpDrawerTrackerService.setDrawer(this.pageHelpDrawer);
    this.pageHelpDrawerTrackerService.setDrawerArticlesMapper(DrawerPageSortMapper);
    this.pageHelpDrawerTrackerService.currentDrawerStatus$.subscribe((isPageHelpOpen) => {
      this.isPageHelpOpen = isPageHelpOpen;
      if (this.isPhcPage) {
        return;
      }
      if (isPageHelpOpen && this.isFPOpen) {
        this.shouldFocusPanelOpen = true;
        this.closeFocusPanel();
      }
      if (!isPageHelpOpen && this.shouldFocusPanelOpen && !this.autoCloseDrawer()) {
        setTimeout(() => {
          this.shouldFocusPanelOpen = false;
            this.toggleFocusPanel(true)
        }, 205);
      }
    });
    this.pageHelpService.currentDrawerStatus$.subscribe((isPageHelpOpen) => {
      this.isPageHelpVisible = isPageHelpOpen ? true : false;
    });
    setTimeout(() => {
      if (!this.focusPanelMFELoaded && !this.isPhcPage) {
        this.loggerService.error(
          { message: 'Focus Panel MFE failed to load' },
          LoggerCode.FOCUS_PANEL_MFE_ERROR,
        );
      }
    }, 4000);
    if (!this.isPhcPage) {
      this.loadSelectedFocusPanelItem();
      this.newFocusPanelListener(true);
    }
    this.changeDetector.detectChanges();
  }

  onDrawerStateChange(event) {
    if (!event?.isDrawerOpen && this.isPageHelpVisible) {
      this.pageHelpService.setCurrentDrawerStatus(false);
    }
  }

  getTitleForRoute(url: string): string {
    switch (url) {
      case '/':
        return 'Portfolio Analysis | Vanguard ';
      case '/asset-mix':
        return 'Asset Mix | Vanguard';
      case '/stock-analysis/market-capitalization':
        return 'Stock Analysis | Vanguard';
      case '/bond-analysis/credit-quality':
        return 'Bond Analysis | Vanguard';
      case '/costs/mutual-fund-costs':
        return 'Investment Costs | Vanguard';
      case '/tester-tool':
        return 'Portfolio Tester | Vanguard';
      default:
        return 'Portfolio Analysis | Vanguard';
    }
  }

  ngOnDestroy(): void {
    this._destroy.complete();
    this.newFocusPanelListener(false)
  }

  navigateToTesterPage() {
    this.router.navigate(['/tester-tool']);
  }

  /*
  Used for Medallia
   */
  private updateSpoid(): void {
    const spoid = this.meta.getTag('name="DCSext.sPoid"')?.content;
    const window = this.windowRef.nativeWindow;
    window.spoid = spoid ? spoid : -1;
  }

  private setRandomNum(): void {
    const randomNumber = Math.floor(Math.random() * 4) + 1;
    const window = this.windowRef.nativeWindow;
    window.randomNum = randomNumber ? randomNumber : -1;
  }

  handleOpenFpDrawer(): void {
    this.pageHelpDrawerTrackerService.closeDrawer();
    setTimeout(() => {
      this.toggleFocusPanel(true)
      this.adobeAnalyticsService.CreateCTATrigger(
        'Secure Site Portfolio Watch',
        'Focus Panel Show Button',
        'Button',
      );
    }, 230);
  }

  getGroupData(event: any): void {
    this.customDisplayName = event.detail.fullDisplayName;
    this.isAccountSelected = event.detail.accounts.some(acc => acc.accountName === event.detail.fullDisplayName)
    this.portfolioDataService.setIsAccountSeleted(this.isAccountSelected)
    if(this.isAccountSelected){
      this.portfolioDataService.setAccountSeleted(event?.detail?.accounts[0]?.accountId)
    }
    this.portfolioDataService.setSelectedAccountGroupDetails({
      GroupName: event.detail.fullDisplayName,
      ViewID: event.detail.clientViewId,
      IsGroup: true,
    });
    this.portfolioDataService.loadChartDataPayload();
  }

  checkWindowWidth() {
    this.toggleFocusPanel(window?.matchMedia(`(min-width: ${this.fpDrawerThreshold}px)`)?.matches || false)
  }

  closeFocusPanel(data?: any) {
    this.toggleFocusPanel(false)
    if (data?.analyticsData) {
      this.adobeAnalyticsService.CreateCTATrigger(
        data.analyticsData.location,
        data.analyticsData.ctaName,
        data.analyticsData.ctaType,
      );
    }
  }

  toggleFocusPanel(open: boolean) {

    const bodyElement = document.querySelector('body');

    if (this.isPhcPage) {
      bodyElement.classList.remove(this.CLASSNAME_FOCUS_PANEL_ACTIVE)
      this.isFPOpen = false;
      document.body.dispatchEvent(new CustomEvent('ss-panel-visibility', { detail: { open: this.isFPOpen } }));
      return;
    }

    const isFocusPanelActive = bodyElement.classList.contains(this.CLASSNAME_FOCUS_PANEL_ACTIVE);

    if (open) {
      if (!isFocusPanelActive) {
        bodyElement.classList.add(this.CLASSNAME_FOCUS_PANEL_ACTIVE)
      }
      this.isFPOpen = true
    } else {
      if (isFocusPanelActive) {
        bodyElement.classList.remove(this.CLASSNAME_FOCUS_PANEL_ACTIVE);
      }
      this.isFPOpen = false;
    }

    document.body.dispatchEvent(new CustomEvent('ss-panel-visibility', { detail: { open: this.isFPOpen } }));

  }

  listenToBodyClassChanges() {
    const bodyElement = document.querySelector('body');
    const observer = new MutationObserver((mutationsList) => {
      const element = mutationsList[0]
      const isBodyChange = element.target == bodyElement
      const isClassChange = element.attributeName === 'class'
      if (isBodyChange && isClassChange) {
        this.isFPOpen = bodyElement.classList.contains(this.CLASSNAME_FOCUS_PANEL_ACTIVE);
        if (this.isFPOpen && this.isPageHelpOpen) {
          this.pageHelpDrawerTrackerService.closeDrawer();
        }
      }
    });

    observer.observe(bodyElement, { attributes: true });
  }

  async loadSelectedFocusPanelItem(): Promise<void> {
    try {
      const detail = await (this.getSelectedFocusPanelItem())
      this.getGroupData({ detail });
    } catch (error) {
      console.log('Response error: ', error)
    }
  }

  getSelectedFocusPanelItem() {
    return lastValueFrom(this.http.get(environment.FOCUS_PANEL_GET_SELECTED_GROUP, { withCredentials: true }))
  }

  shouldRenderHeader() {
    return !this.withoutPageHelpPagesURLs.some(route => this.router.url.includes(route))
  }
}
