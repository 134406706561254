import { Component, Input, OnChanges } from '@angular/core';
import { ModernizedStackedBarChartData } from 'src/app/models/chart-models/modernized-stacked-bar-chart';

@Component({
  selector: 'phc-factor-card',
  templateUrl: './factor-card.component.html',
  styleUrls: ['./factor-card.component.scss']
})
export class FactorCardComponent implements OnChanges  {
  

  @Input() idChartData: string;
  @Input() actualChartData: ModernizedStackedBarChartData;
  @Input() targetChartData?: ModernizedStackedBarChartData;

  isAssetMixChart: boolean = false;

  ngOnChanges(changes): void {    
    this.actualChartData = changes?.actualChartData?.currentValue
    this.targetChartData = changes?.targetChartData?.currentValue
    this.isAssetMixChart = !!this.actualChartData && !!this.targetChartData
  }

}
