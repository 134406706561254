import { Component, Input } from '@angular/core';
import { PageHelpItemContenCard } from '../page-help-content/phc-page-help';

@Component({
  selector: 'page-help-card',
  templateUrl: './page-help-card.component.html',
  styleUrls: ['./page-help-card.component.scss']
})
export class PageHelpCardComponent {
  @Input() cardContent: PageHelpItemContenCard
}
