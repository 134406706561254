<div class="container-fluid">
    <div class="flex-row">
      <div
        class="c11n-card c11n-card--density-medium c11n-card--icon-above-start c11n-card--content-left c11n-card--color-white"
      >
        <div class="c11n-card__icon-and-content">
          <div class="c11n-card__content flex-column card">
              <div class="card-title">
                  {{cardContent.title}}
              </div>
              <div class='flex-column card-description'>
                  <ng-container *ngFor="let content of cardContent.content">
                      <page-help-parser [item]="content"></page-help-parser>
                  </ng-container>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>