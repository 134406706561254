<svg width="116" height="101" viewBox="0 0 116 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M52.6675 92.4833L53.0057 99.9623H59.5344L56.5305 91.6243L52.6675 92.4833Z" fill="white" stroke="#040505"
        stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M41.9721 92.1828L43.156 100H41.2607L40.7856 97.6323L39.6017 100H32.7321C32.7321 100 32.4959 99.2886 33.6797 98.8161C34.8636 98.3437 36.0475 97.3961 36.7589 95.737C37.4702 94.078 37.4702 91.9465 37.4702 91.9465L41.9721 92.1828Z"
        fill="white" stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M31.7793 35.1507C31.7793 35.1507 31.194 34.7051 30.6625 34.7024C30.131 34.6997 30.7028 35.4541 31.4061 35.9265"
        fill="white" />
    <path
        d="M31.7793 35.1507C31.7793 35.1507 31.194 34.7051 30.6625 34.7024C30.131 34.6997 30.7028 35.4541 31.4061 35.9265"
        stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path d="M34.1117 39.0647C34.1117 39.0647 33.9506 38.8177 34.1654 38.321L34.1117 39.0647Z" fill="white" />
    <path d="M34.1117 39.0647C34.1117 39.0647 33.9506 38.8177 34.1654 38.321" stroke="#040505" stroke-width="0.268449"
        stroke-miterlimit="10" />
    <path d="M34.0881 32.2057L30.5781 33.2102L32.3035 39.2392L35.8135 38.2347L34.0881 32.2057Z" fill="white"
        stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M31.5879 32.9675L33.2009 32.5059L33.2246 32.5885C33.2785 32.7769 33.1698 32.9727 32.9814 33.0267L32.0497 33.2933C31.8613 33.3472 31.6654 33.2385 31.6115 33.0501L31.5879 32.9675Z"
        fill="#040505" />
    <path
        d="M31.6634 37.5911C31.599 37.5911 31.5319 37.5857 31.4621 37.5723C31.1292 37.5132 31.1239 37.3763 31.1534 37.2206C31.1829 37.0488 31.25 36.9897 31.4138 36.9897C31.4594 36.9897 31.5077 36.9951 31.5641 37.0059C31.9077 37.0676 32.1413 37.2636 32.1171 37.3978C32.101 37.4917 31.9346 37.5938 31.6634 37.5938V37.5911Z"
        fill="white" />
    <path
        d="M31.4138 37.1237C31.4514 37.1237 31.4917 37.129 31.54 37.1371C31.8272 37.1881 31.9749 37.3331 31.9856 37.368C31.9722 37.3921 31.8675 37.4592 31.6635 37.4592C31.6071 37.4592 31.5453 37.4539 31.4863 37.4431C31.2581 37.4029 31.2662 37.3572 31.2849 37.2472C31.3064 37.1317 31.3225 37.1264 31.4138 37.1264M31.4138 36.8552C31.1454 36.8552 31.0568 37.0029 31.0219 37.1962C30.9816 37.427 31.0353 37.6337 31.4407 37.7035C31.5185 37.7169 31.5937 37.7223 31.6635 37.7223C31.9722 37.7223 32.2165 37.6042 32.2487 37.4163C32.2889 37.1854 31.9937 36.9411 31.5883 36.8686C31.5239 36.8579 31.4648 36.8525 31.4138 36.8525V36.8552Z"
        fill="#040505" />
    <path
        d="M32.0961 38.4043C32.0316 38.4043 31.9645 38.3989 31.8947 38.3855C31.5619 38.3265 31.5565 38.1895 31.586 38.0312C31.6155 37.8594 31.6827 37.8003 31.8464 37.8003C31.892 37.8003 31.9404 37.8057 31.9967 37.8164C32.1685 37.8459 32.3269 37.9157 32.4316 38.0016C32.5175 38.0714 32.5578 38.1466 32.5471 38.2083C32.531 38.3023 32.3645 38.4043 32.0934 38.4043H32.0961Z"
        fill="white" />
    <path
        d="M31.8459 37.9372C31.8835 37.9372 31.9238 37.9425 31.9721 37.9506C32.2593 38.0016 32.407 38.1465 32.4177 38.1814C32.4043 38.2056 32.2996 38.2727 32.0956 38.2727C32.0392 38.2727 31.9775 38.2673 31.9184 38.2566C31.7547 38.2271 31.7224 38.1841 31.7198 38.1788C31.7144 38.1734 31.7037 38.1465 31.7198 38.0606C31.7412 37.9452 31.7573 37.9398 31.8486 37.9398M31.8459 37.6687C31.5775 37.6687 31.4889 37.8163 31.454 38.0096C31.4137 38.2405 31.4674 38.4472 31.8728 38.517C31.9506 38.5304 32.0258 38.5358 32.0956 38.5358C32.4043 38.5358 32.6486 38.4177 32.6808 38.2298C32.7211 37.9989 32.4258 37.7546 32.0204 37.6821C31.956 37.6714 31.8969 37.666 31.8459 37.666V37.6687Z"
        fill="#040505" />
    <path
        d="M33.2933 41.5291C33.2933 41.5291 32.7001 39.6499 32.4289 39.3278C32.1578 39.0057 32.3672 39.2285 32.3672 39.2285C32.3672 39.2285 33.2907 38.9654 33.5591 38.8875C33.9564 38.7748 34.0477 38.6486 34.0477 38.6486C34.0477 38.6486 34.4289 37.505 35.1188 36.5654C35.1188 36.5654 35.0168 35.8863 34.9228 35.242C34.9228 35.242 33.6611 34.8232 33.9323 34.1279L35.202 34.5897C35.3658 34.6541 35.5 34.7722 35.5886 34.9252L36.3617 36.2621C36.4718 36.45 36.4959 36.6755 36.4315 36.8849C36.206 37.6097 35.6825 39.3251 35.645 39.7895C35.5966 40.3828 35.7228 40.8204 35.7228 40.8204"
        fill="white" />
    <path
        d="M33.2933 41.5291C33.2933 41.5291 32.7001 39.6499 32.4289 39.3278C32.1578 39.0057 32.3672 39.2285 32.3672 39.2285C32.3672 39.2285 33.2907 38.9654 33.5591 38.8875C33.9564 38.7748 34.0477 38.6486 34.0477 38.6486C34.0477 38.6486 34.4289 37.505 35.1188 36.5654C35.1188 36.5654 35.0168 35.8863 34.9228 35.242C34.9228 35.242 33.6611 34.8232 33.9323 34.1279L35.202 34.5897C35.3658 34.6541 35.5 34.7722 35.5886 34.9252L36.3617 36.2621C36.4718 36.45 36.4959 36.6755 36.4315 36.8849C36.206 37.6097 35.6825 39.3251 35.645 39.7895C35.5966 40.3828 35.7228 40.8204 35.7228 40.8204"
        stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path d="M0.279045 21.197V0.279045H38.4905V21.197H0.279045Z" fill="#DEF5F0" stroke="black"
        stroke-width="0.289536" />
    <mask id="path-16-inside-1_6759_18981" fill="white">
        <path d="M38.501 0.268449V21.2075H0.268449V0.268449H38.501ZM38.7694 0H0V21.4759H38.7694V0Z" />
    </mask>
    <path d="M38.501 0.268449V21.2075H0.268449V0.268449H38.501ZM38.7694 0H0V21.4759H38.7694V0Z" fill="#DEF5F0" />
    <path
        d="M38.501 0.268449H38.7905V-0.0210867H38.501V0.268449ZM38.501 21.2075V21.497H38.7905V21.2075H38.501ZM0.268449 21.2075H-0.0210867V21.497H0.268449V21.2075ZM0.268449 0.268449V-0.0210867H-0.0210867V0.268449H0.268449ZM38.7694 0H39.059V-0.289536H38.7694V0ZM0 0V-0.289536H-0.289536V0H0ZM0 21.4759H-0.289536V21.7655H0V21.4759ZM38.7694 21.4759V21.7655H39.059V21.4759H38.7694ZM38.2114 0.268449V21.2075H38.7905V0.268449H38.2114ZM38.501 20.9179H0.268449V21.497H38.501V20.9179ZM0.557985 21.2075V0.268449H-0.0210867V21.2075H0.557985ZM0.268449 0.557985H38.501V-0.0210867H0.268449V0.557985ZM38.7694 -0.289536H0V0.289536H38.7694V-0.289536ZM-0.289536 0V21.4759H0.289536V0H-0.289536ZM0 21.7655H38.7694V21.1864H0V21.7655ZM39.059 21.4759V0H38.4799V21.4759H39.059Z"
        fill="black" mask="url(#path-16-inside-1_6759_18981)" />
    <path
        d="M4.25195 17.5322L7.9834 15.4437H10.0182L15.108 13.8384C15.108 13.8384 17.1429 14.1605 17.1429 13.9995C17.1429 13.8384 20.8743 9.0224 20.8743 9.0224L24.2675 9.50293L30.3747 4.52588L35.1236 5.00641"
        stroke="#00BDA3" stroke-width="0.579072" stroke-miterlimit="10" />
    <path
        d="M48.4387 23.3094C49.5555 22.743 50.742 23.9081 51.4991 24.9658C52.2561 26.0235 52.2131 27.9241 52.3044 29.2609C52.4735 31.7817 51.7675 34.8984 52.5729 36.7775C52.6749 37.0164 51.7621 36.1681 51.6038 36.3641C51.2548 36.7909 50.6561 36.8312 50.1407 36.7319C48.9434 36.501 47.8777 35.6796 47.1717 34.6004C46.4656 33.5212 46.1032 32.2058 46.0146 30.8851"
        fill="#040505" />
    <path
        d="M31.5 41.7437L36.6005 39.5961C36.3321 42.549 36.2542 43.9342 36.2542 43.9342L43.446 34.3613L46.6378 39.3357C45.7117 40.788 41.7199 46.8201 39.0058 49.5824C37.9562 50.6535 37.5213 50.871 36.0663 50.871C35.9777 50.871 35.8274 50.8414 35.5992 50.7609C34.0932 50.2374 32.9872 48.9408 32.649 47.3811L31.5027 41.7437H31.5Z"
        fill="#C20029" stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M52.0145 52.7019H38.8122C38.8122 52.7019 36.7747 71.5927 36.7747 76.3147L35.5801 92.3116L43.2148 92.3599C43.2148 92.3599 44.5033 79.5334 44.7181 76.7442C44.9328 73.955 45.3624 64.7231 45.3624 64.7231C45.3624 64.7231 47.9368 77.1737 48.7958 80.6072C49.6549 84.0407 50.2992 92.5747 50.2992 92.5747H58.5647C58.5647 92.5747 55.7755 75.0261 55.5607 72.0222C55.346 69.0156 52.0172 52.7019 52.0172 52.7019"
        fill="#040505" />
    <path
        d="M44.5708 33.3871C44.5708 33.3871 43.0541 33.9105 41.5132 37.7306C40.9414 39.148 40.5441 40.6298 40.2918 42.1358L35.9805 67.7459L56.6511 69.3566L53.4673 50.4604L49.913 34.0582C49.913 34.0582 46.8795 31.8193 44.5172 33.4085"
        fill="#C20029" />
    <path
        d="M44.5708 33.3871C44.5708 33.3871 43.0541 33.9105 41.5132 37.7306C40.9414 39.148 40.5441 40.6298 40.2918 42.1358L35.9805 67.7459L56.6511 69.3566L53.4673 50.4604L49.913 34.0582C49.913 34.0582 46.8795 31.8193 44.5172 33.4085"
        stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M43.043 25.6986C43.5289 23.6342 44.7342 23.1913 46.0872 22.8799C47.4402 22.5658 48.9945 23.0571 49.7784 24.2033C50.0978 24.6731 50.283 25.2208 50.4253 25.7711C50.6938 26.8207 50.8119 27.975 50.3448 28.9549C49.7381 30.2273 48.1086 30.9038 46.7798 30.4367"
        fill="#040505" />
    <path
        d="M47.2657 27.2474C47.2657 27.2474 47.6979 25.7306 46.9489 24.783C46.1999 23.8354 44.557 24.0448 43.8134 24.7669C43.0698 25.489 42.4604 27.2393 42.4121 28.4984C42.3638 29.7574 42.6752 30.552 43.3195 30.6782C43.9611 30.8043 44.8094 30.9037 44.8094 30.9037C44.8094 30.9037 44.8416 31.6124 44.7315 32.4177C44.6859 32.7452 44.6188 33.0915 44.5141 33.411C44.5141 33.411 44.2725 33.7519 44.4899 34.4311C44.8657 35.6069 46.0442 35.2955 47.3999 34.5895C48.1515 34.1975 48.9032 33.513 48.9032 33.513C48.9032 33.513 48.565 33.0083 48.2858 32.5036C48.2187 32.3855 48.1569 32.2674 48.1032 32.1546C47.8187 31.564 47.1959 29.9748 47.5287 29.3037C47.8616 28.6353 48.5757 27.7064 48.2911 27.1185C48.0066 26.5279 47.263 27.2501 47.263 27.2501"
        fill="white" />
    <path
        d="M47.2657 27.2474C47.2657 27.2474 47.6979 25.7306 46.9489 24.783C46.1999 23.8354 44.557 24.0448 43.8134 24.7669C43.0698 25.489 42.4604 27.2393 42.4121 28.4984C42.3638 29.7574 42.6752 30.552 43.3195 30.6782C43.9611 30.8043 44.8094 30.9037 44.8094 30.9037C44.8094 30.9037 44.8416 31.6124 44.7315 32.4177C44.6859 32.7452 44.6188 33.0915 44.5141 33.411C44.5141 33.411 44.2725 33.7519 44.4899 34.4311C44.8657 35.6069 46.0442 35.2955 47.3999 34.5895C48.1515 34.1975 48.9032 33.513 48.9032 33.513C48.9032 33.513 48.565 33.0083 48.2858 32.5036C48.2187 32.3855 48.1569 32.2674 48.1032 32.1546C47.8187 31.564 47.1959 29.9748 47.5287 29.3037C47.8616 28.6353 48.5757 27.7064 48.2911 27.1185C48.0066 26.5279 47.263 27.2501 47.263 27.2501"
        stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M44.8123 30.9037C44.8123 30.9037 43.964 30.8043 43.3224 30.6782C42.6808 30.552 42.3694 29.7574 42.415 28.4984C42.4633 27.2393 43.0727 25.489 43.8163 24.7669C44.5599 24.0448 46.2029 23.8354 46.9518 24.783C47.7008 25.7306 47.2686 27.2474 47.2686 27.2474C47.2686 27.2474 48.0122 26.5253 48.2967 27.1158C48.5813 27.7064 47.8645 28.6326 47.5344 29.301C47.2015 29.9695 47.8243 31.5614 48.1088 32.152C48.3934 32.7425 48.9088 33.5103 48.9088 33.5103"
        stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M48.8661 33.387L52.7398 35.8809C53.7679 36.4258 54.5679 37.3197 54.9974 38.3989C55.98 40.8686 57.8081 45.5262 58.8792 48.6671C58.9168 48.7745 58.949 48.8845 58.9812 48.9973C60.0604 52.8039 57.1826 56.5622 53.2821 56.7689C53.0566 56.7796 52.8337 56.7689 52.6082 56.7581L48.4124 56.5085L47.8755 50.8711L53.4673 50.4603L51.7492 44.0202"
        fill="#C20029" />
    <path
        d="M48.8661 33.387L52.7398 35.8809C53.7679 36.4258 54.5679 37.3197 54.9974 38.3989C55.98 40.8686 57.8081 45.5262 58.8792 48.6671C58.9168 48.7745 58.949 48.8845 58.9812 48.9973C60.0604 52.8039 57.1826 56.5622 53.2821 56.7689C53.0566 56.7796 52.8337 56.7689 52.6082 56.7581L48.4124 56.5085L47.8755 50.8711L53.4673 50.4603L51.7492 44.0202"
        stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M46.2242 41.7195C46.2242 41.7195 41.1639 56.511 41.1236 56.4842C41.0834 56.4573 51.8616 56.7526 51.8616 56.7526L56.9621 42.2564L46.2242 41.7195Z"
        fill="#040505" />
    <path
        d="M47.0163 51.9449L45.4056 50.8711L42.1842 51.1395C42.1842 51.4348 42.4258 51.6764 42.7211 51.6764H43.7949C43.8352 51.7597 43.811 51.8536 43.752 51.9422C43.6016 52.1623 43.3037 52.2321 43.0567 52.1328L42.5279 51.9207C42.195 51.7865 41.8165 51.9798 41.7359 52.3288C41.5587 53.0858 41.3816 54.2724 41.9158 54.6267C43.0325 55.3703 46.7479 54.8952 46.7479 54.8952"
        fill="white" />
    <path
        d="M47.0163 51.9449L45.4056 50.8711L42.1842 51.1395C42.1842 51.4348 42.4258 51.6764 42.7211 51.6764H43.7949C43.8352 51.7597 43.811 51.8536 43.752 51.9422C43.6016 52.1623 43.3037 52.2321 43.0567 52.1328L42.5279 51.9207C42.195 51.7865 41.8165 51.9798 41.7359 52.3288C41.5587 53.0858 41.3816 54.2724 41.9158 54.6267C43.0325 55.3703 46.7479 54.8952 46.7479 54.8952"
        stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M54.9892 49.6631L45.325 49.9315C45.325 49.9315 44.5573 56.5005 44.6539 56.5085C45.3761 56.734 53.2443 56.777 53.2443 56.777C53.2443 56.777 55.306 56.8522 56.9596 55.3381"
        fill="#C20029" />
    <path
        d="M54.9892 49.6631L45.325 49.9315C45.325 49.9315 44.5573 56.5005 44.6539 56.5085C45.3761 56.734 53.2443 56.777 53.2443 56.777C53.2443 56.777 55.306 56.8522 56.9596 55.3381"
        stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M45.1237 23.7577C44.7962 24.1657 44.8097 24.7697 45.0164 25.2502C45.2231 25.7308 45.5989 26.1173 45.9613 26.4958C46.3237 26.8744 46.7318 27.7065 46.9331 28.1898C47.1935 27.618 47.4136 26.5898 47.4888 25.967C47.564 25.3442 47.4324 24.6677 47.0029 24.2086C46.8042 23.9966 46.5519 23.8409 46.2888 23.7174C45.8486 23.5107 45.368 23.3872 44.8821 23.3577"
        fill="#040505" />
    <path d="M45.3247 5.10304V18.7939H50.2561V5.10304H45.3247Z" fill="#00BDA3" stroke="#040505" stroke-width="0.268449"
        stroke-miterlimit="10" />
    <path d="M55.1875 2.35143V18.7939H60.1189V2.35143H55.1875Z" fill="#00BDA3" stroke="#040505" stroke-width="0.268449"
        stroke-miterlimit="10" />
    <path d="M65.3594 0.539402V18.7939H70.2908V0.539402H65.3594Z" fill="#00BDA3" stroke="#040505"
        stroke-width="0.268449" stroke-miterlimit="10" />
    <path d="M77.3245 45.0887V0.278801H115.536V45.0887H77.3245Z" fill="#DEF5F0" stroke="black"
        stroke-width="0.289536" />
    <mask id="path-40-inside-2_6759_18981" fill="white">
        <path d="M115.546 0.268449V45.0995H77.3134V0.268449H115.546ZM115.814 0H77.0449V45.3679H115.814V0Z" />
    </mask>
    <path d="M115.546 0.268449V45.0995H77.3134V0.268449H115.546ZM115.814 0H77.0449V45.3679H115.814V0Z" fill="#DEF5F0" />
    <path
        d="M115.546 0.268449H115.835V-0.0210867H115.546V0.268449ZM115.546 45.0995V45.389H115.835V45.0995H115.546ZM77.3134 45.0995H77.0238V45.389H77.3134V45.0995ZM77.3134 0.268449V-0.0210867H77.0238V0.268449H77.3134ZM115.814 0H116.104V-0.289536H115.814V0ZM77.0449 0V-0.289536H76.7554V0H77.0449ZM77.0449 45.3679H76.7554V45.6574H77.0449V45.3679ZM115.814 45.3679V45.6574H116.104V45.3679H115.814ZM115.256 0.268449V45.0995H115.835V0.268449H115.256ZM115.546 44.8099H77.3134V45.389H115.546V44.8099ZM77.6029 45.0995V0.268449H77.0238V45.0995H77.6029ZM77.3134 0.557985H115.546V-0.0210867H77.3134V0.557985ZM115.814 -0.289536H77.0449V0.289536H115.814V-0.289536ZM76.7554 0V45.3679H77.3345V0H76.7554ZM77.0449 45.6574H115.814V45.0784H77.0449V45.6574ZM116.104 45.3679V0H115.525V45.3679H116.104Z"
        fill="black" mask="url(#path-40-inside-2_6759_18981)" />
    <path d="M85.5386 26.3455V34.9251" stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path d="M91.7588 23.21V35.0888" stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path d="M96.9937 13.3123V35.0888" stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path d="M101.904 14.3003L102.231 35.089" stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path d="M107.465 6.71118V35.0889" stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path d="M70.42 21.3416H45.1831" stroke="#040505" stroke-width="0.268449" stroke-miterlimit="10" />
    <path
        d="M83.209 34.9734L86.8089 30.6836H88.7713L93.6812 27.3843C93.6812 27.3843 95.6436 28.0447 95.6436 27.7145C95.6436 27.3843 99.2435 17.4866 99.2435 17.4866L102.516 18.4772L108.406 8.24927L112.988 9.23985"
        stroke="#00BDA3" stroke-width="0.579072" stroke-miterlimit="10" />
    <path d="M71.0966 99.9973H5.05811" stroke="#040505" stroke-width="0.579072" stroke-miterlimit="10" />
</svg>