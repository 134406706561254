<svg #svgGraphGlobalStock [attr.width]="svgWidth" height="120" style="margin-left: -2px;">
    <line x1="10" y1="60" [attr.x2]="svgWidth - 10" y2="60" stroke="#E9E9EB" stroke-width="4"></line>
    <defs>
        <pattern id="diagonal-stripes" patternUnits="userSpaceOnUse" width="5" height="5">
            <path d="M0,5 l5,-5 M-1,1 l2,-2 M4,6 l2,-2" stroke="#8DCFCB" stroke-width="2"></path>
        </pattern>
    </defs>
    <rect [attr.x]="barStartX" y="50" [attr.width]="barWidth" height="20" fill="white"></rect>
    <rect [attr.x]="barStartX" y="50" [attr.width]="barWidth" height="20" fill="url(#diagonal-stripes)" stroke="#8DCFCB"
        stroke-width="1"></rect>
    <polygon [attr.points]="arrowX + ',74 ' + (arrowX - 10) + ',88 ' + (arrowX + 10) + ',88'" fill="black"></polygon>
    <text [attr.x]="barStartX + 18" y="42" text-anchor="end" font-size="14px" fill="black">{{ range[0] }}%</text>
    <text [attr.x]="barStartX + barWidth - 28" y="42" text-anchor="start" font-size="14px" fill="black">{{ range[1]
        }}%</text>
</svg>