<div class="flex-column main">
  <div class="flex-row main__intro">
    <div class="main__intro-image">
      <phc-meter-illustration-image-component
        aria-hidden="true"
      ></phc-meter-illustration-image-component>
    </div>
    <div class="flex-column main__intro-description">
      <span aria-label="Beta version">BETA*</span>
      <h2>How diversified is your retirement portfolio?</h2>
      <p>
        A diversified portfolio helps set you up for long-term investing success. Share your
        retirement goals with us to uncover how diversified your portfolio is and provide tips to
        consider if you’d like to further diversify.
      </p>
      <c11n-button
        [ariaLabelText]="'Get started'"
        [href]="urls.setGoals"
        [iconName]="'link-right'"
        [iconPosition]="'trailing'"
        [labelText]="'Get started'"
        [size]="'small'"
      ></c11n-button>
    </div>
  </div>
  <div class="flex-row main__score-factor-description">
    <div class="flex-column main__score-factor-description-score">
      <h2>How do you measure my portfolio’s diversification?</h2>
      <p>
         We’ll compare your current portfolio against targets in three portfolio factors: asset mix,
        global stock exposure, and stock concentration.
      </p>
    </div>
    <div class="flex-row main__score-factor-description-factor">
      <div class="flex-column main__score-factor-description-factor--text">
        <h3>Asset mix</h3>
        <p>
          Having a balanced asset mix can help align your portfolio to your individual risk
          tolerance, time horizon, and financial goals.
        </p>
      </div>
      <div class="flex-column main__score-factor-description-factor--text">
        <h3>Global stock exposure</h3>
        <p>
          Owning international and domestic stock increases diversification while potentially
          reducing risk and volatility.
        </p>
      </div>
      <div class="flex-column main__score-factor-description-factor--text">
        <h3>Stock concentration</h3>
        <p>
          Concentrating your investments in one security ties your risk to one company’s ups and downs.
        </p>
      </div>
    </div>
  </div>
  <phc-disclosure-box [tag]="'no-goals'"></phc-disclosure-box>
</div>
