import { Component } from '@angular/core';

@Component({
  selector: 'phc-meter-illustration-image-component',
  templateUrl: './meter-illustration.component.html',
  styleUrls: ['./meter-illustration.component.scss']
})
export class MeterIllustrationComponent {


}
