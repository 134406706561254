import { Component } from '@angular/core';

@Component({
  selector: 'failure-card',
  templateUrl: './failure-card.component.html',
  styleUrls: ['./failure-card.component.scss'],
})
export class FailureCardComponent {
  windowRef = window
}
