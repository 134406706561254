import { Component, Input } from '@angular/core';
import { PhcUrl } from '../../../../app/models/interfaces/PhcData';
import { getPHCUrls } from '../../../../app/utils/functions';

@Component({
  selector: 'phc-gauge-card',
  templateUrl: './gauge-card.component.html',
  styleUrls: ['./gauge-card.component.scss']
})
export class GaugeCardComponent {

  @Input() percent = 0;
  @Input() text = '';


  urls: PhcUrl = {}

  constructor() {
    this.urls = getPHCUrls()
  }


}
