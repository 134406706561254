<div id="target-success-banner" class="c11n-banner c11n-banner--large c11n-banner--success c11n-banner--collapsible">
  <div class="c11n-banner__leading-icon">
    <svg class="c11n-success-fill-medium"><use xlink:href="#success-fill-medium"></use></svg>

  </div>
  <span class="c11n-banner__close-icon">
                    <button 
                    type="button" 
                    aria-label="close content" 
                    title="close content" 
                    class="c11n--on-light c11n-button c11n-button--small c11n-button--icon-ghost"
                    (click)="closeBanner()"
                    >
                    <span class="c11n-button__box">
                        <span class="c11n-button__icon-only">
                            <svg class="c11n-remove-small">
                                <use xlink:href="#remove-small"></use>
                            </svg>
                        </span>
                    </span>
                </button>
                </span>
  <div class="c11n-banner__main" #content="pwContent" pwContent>
    <div class="c11n-banner__content c11n-banner__content--expanded">
      <div class="c11n-banner__content-header" [innerHTML]="content['PWTargetAssetMixBanner_Header'] | getFromAEM"></div>
    </div>
    <div class="c11n-banner__body c11n-text-medium" >
      <span [innerHTML]="content['PWTargetAssetMixBanner_Body_1']"></span>
      <span class="link" [innerHTML]="content['PWTargetAssetMixBanner_Body_Link']" (click)="navigateToPage()"></span>
      <span [innerHTML]="content['PWTargetAssetMixBanner_Body_2']"></span>
    </div>
  </div>
</div>