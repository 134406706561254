import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelBrokerService {

  private isEligible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public isEligible$ = this.isEligible.asObservable();
  private isFailure = new BehaviorSubject(false);

  async validateUserChannelBroker(): Promise<void> {

    try {
      const response = await fetch(environment.PW3_API + "/phc-eligibility" , {
        method: 'GET',
        credentials: 'include'
      })

      if(!response.ok){
        throw await response.json()
      }
    
      const isEligible = await response.json();
      this.isEligible.next(isEligible);

    } catch (error) {
      console.error('Error fetching channel broker interventions:', error);
      this.isFailure.next(true);
      this.isEligible.next(false);
      this.isEligible.error(error)
    }
  }

  getisEligible() {
    return this.isEligible.asObservable();
  }

  getFailure(): Observable<boolean> {
      return this.isFailure.asObservable();
  }

}
