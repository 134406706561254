import { Component, Input, OnChanges, SimpleChanges, ElementRef, ViewChild, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { ModernizedStackedBarChartData } from 'src/app/models/chart-models/modernized-stacked-bar-chart';

@Component({
  selector: 'range-chart',
  templateUrl: './range-chart.component.html',
  styleUrls: ['./range-chart.component.scss']
})
export class RangeChartComponent implements OnChanges, AfterViewInit, OnDestroy {

  private resizeObserver!: ResizeObserver;
  currentValue: number = 0;
  range: number[] = [0, 100];
  svgWidth: number = 0;
  barStartX: number = 0;
  barWidth: number = 0;
  arrowX: number = 0;

  @Input() rangeChartData: ModernizedStackedBarChartData;

  @ViewChild('svgGraphGlobalStock', { static: true }) svgGraph!: ElementRef<SVGElement>;

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.svgGraph.nativeElement.parentElement.parentElement);
      this.resizeObserver.disconnect();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateGraphWidth();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const internationalStocks = changes.rangeChartData.currentValue?.chartSlices?.find(slice => slice?.name === 'bonds')
    this.range = internationalStocks?.range
    this.currentValue = internationalStocks?.value
    this.updateGraph();
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit: graphContainer', this.svgGraph.nativeElement.parentElement.parentElement);
    console.log('ngAfterViewInit: svgGraph', this.svgGraph);
    this.updateGraphWidth();
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === this.svgGraph.nativeElement.parentElement.parentElement) {
          console.log('ResizeObserver: graphContainer size changed');
          this.updateGraphWidth();
        }
      }
    });
    this.resizeObserver.observe(this.svgGraph.nativeElement.parentElement.parentElement);
  }

  updateGraphWidth(): void {
    console.log('updateGraphWidth called');
    if (this.svgGraph && this.svgGraph.nativeElement.parentElement.parentElement) {
      this.svgWidth = this.svgGraph.nativeElement.parentElement.parentElement.offsetWidth;
      console.log('svgWidth:', this.svgWidth);
      if (this.svgWidth < 0) this.svgWidth = 0;
      this.updateGraph();
    }
  }

  updateGraph(): void {
    console.log('updateGraph called');
    const [rangeStart, rangeEnd] = this.range;
    this.barStartX = 10 + (rangeStart / 100) * (this.svgWidth - 20);
    this.barWidth = ((rangeEnd - rangeStart) / 100) * (this.svgWidth - 20);
    this.arrowX = 10 + (this.currentValue / 100) * (this.svgWidth - 20);
  }
}
