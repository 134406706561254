<div class="flex-column main">
  <div class="flex-column main__rebalance-text">
    <div class="flex-column main__rebalance-text--content">
      <h2 class="flex-row">
        Rebalancing your portfolio
      </h2>
      <p>
        Maintaining a diversified portfolio to help you reach your goals follows a general, repeatable process:
      </p>
    </div>
    <ol class="flex-row" role="list">
      <div>
        <li>Review your portfolio factors</li>
        <p>Find out which ones are out of alignment with your targets.</p>
      </div>
      <div>
        <li>Consider rebalancing actions</li>
        <p>Review different strategies to get aligned and make any trades to adjust your investments.</p>
      </div>
      <div>
        <li>Check back in</li>
        <p>Make sure your portfolio hasn’t shifted too far out of alignment over time.</p>
      </div>
    </ol>
  </div>
  <div class="flex-column main__card">
    <div class="flex-column main__plan-and-take-action-header">
      <h3 class="flex-row main__steps--title">
        <span class="flex-row">1</span> Review the assessment of your portfolio factors
        <c11n-icon-button [labelText]="'Help Button'" [variant]="'ghost'" [iconName]="'help'" [size]="'small'"
            aria-label="Help button" (clickEvent)="openPageHelpDrawer('portfolioFactor')"></c11n-icon-button>
      </h3>      
      <p class="main__plan-and-take-action-description">
        Your diversification analysis is based on the account(s) associated with your goal, your target retirement date,
        and risk tolerance.
      </p>
    </div>
    <div class="flex-row main__card-area">
      <div class="main__card-area--card">
        <c11n-card>
          <div cardBody class="flex-column">
            <phc-aligned-chip [isAligned]="data.portfolioFactors.assetMix.aligned"
              aria-label="Asset mix alignment status"></phc-aligned-chip>
            <div class="main__card-area--title">
              <h4>Asset mix</h4>
            </div>
            <phc-factor-card [actualChartData]="actualChartDataAssetMix" [targetChartData]="targetChartDataAssetMix"
              aria-label="Asset mix factor card"></phc-factor-card>
            <phc-aligned-card [isAligned]="data.portfolioFactors.assetMix.aligned"
              aria-label="Asset mix alignment card">
              <div class="main__card-area--disclaimer">
                <div class="flex-column">
                  <h5>Our analysis</h5>
                  <ng-container *ngIf="data.portfolioFactors.assetMix.aligned">
                    <p>Great work aligning to your target asset allocation by investing in a balanced mix of stocks,
                      bonds, and cash!</p>
                    <p>This can help balance your overall risk and set you up for success to meet your long-term goals.
                    </p>
                  </ng-container>
                  <ng-container *ngIf="!data.portfolioFactors.assetMix.aligned">
                    <p>Your current asset mix has <b>{{assetMixAboveTarget}}{{assetMixBelowTarget}}</b> than your target
                      suggests.</p>
                    <p><b>Adding more {{assetMixAddingMore}}
                        <span *ngIf="assetMixAboveTarget">
                          by reducing your {{assetMixAboveTarget.replace('more', '')}}
                        </span></b> could help balance your
                      risk and bring your asset mix into alignment.</p>
                  </ng-container>
                </div>
              </div>
            </phc-aligned-card>
          </div>
        </c11n-card>
      </div>
      <div class="main__card-area--card">
        <c11n-card>
          <div cardBody class="flex-column">
            <phc-aligned-chip [isAligned]="data.portfolioFactors.domesticStock.aligned"
              aria-label="Global stock exposure alignment status"></phc-aligned-chip>
            <div class="main__card-area--title">
              <h4>Global stock exposure</h4>
            </div>
            <div class="main__card-area--sub-title">
              Your current international stock exposure
            </div>
            <phc-factor-card [actualChartData]="chartDataGlobalStock" [targetChartData]="null"></phc-factor-card>
            <phc-aligned-card [isAligned]="data.portfolioFactors.domesticStock.aligned">
              <div class="main__card-area--disclaimer">
                <div class="flex-column">
                  <h5>Our analysis</h5>
                  <ng-container *ngIf="data.portfolioFactors.domesticStock.aligned">
                    <p>Great work! Your international stock allocation is within the target range.</p>
                    <p>This means you have a good mix of domestic and international stocks, which improves the balance
                      of your portfolio and reduces risk.</p>
                  </ng-container>
                  <ng-container *ngIf="!data.portfolioFactors.domesticStock.aligned">
                    <p>Your current portfolio is <b>{{globalStockOverUnder}} invested in international stocks</b>, which
                      is not within the target range.</p>
                    <p>Adding more <b>{{globalStockAddingMore}}</b> stocks could help reduce your risk and improve your
                      diversification.</p>
                  </ng-container>
                </div>
              </div>
            </phc-aligned-card>
          </div>
        </c11n-card>
      </div>
      <div class="main__card-area--card">
        <c11n-card>
          <div cardBody class="flex-column">
            <phc-aligned-chip [isAligned]="data.portfolioFactors.singleStock.aligned"
              aria-label="Single-stock concentration alignment status"></phc-aligned-chip>
            <div class="main__card-area--title">
              <h4>Stock concentration</h4>
            </div>
            <phc-aligned-card [isAligned]="data.portfolioFactors.singleStock.aligned"
              aria-label="Single-stock concentration alignment card">
              <div class="main__card-area--disclaimer">
                <div class="flex-column">
                  <h5>Our analysis</h5>
                  <div class="main__card-area--paragraph">
                    <ng-container *ngIf="data.portfolioFactors.singleStock.aligned">
                      <p>Great job avoiding too much concentration in a single company!</p>
                      <p>This helps reduce your portfolio’s risk, as you’re not overly exposed to the ups and downs of a
                        single company’s performance.</p>
                    </ng-container>
                    <ng-container *ngIf="!data.portfolioFactors.singleStock.aligned">
                      <p>You’re currently over invested in at least one company’s stock.</p>
                      <p>This can expose your portfolio to extra risk. <b>Reducing your single-stock exposure to below
                          [X]% for a single company</b> could help improve your overall diversification.</p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </phc-aligned-card>
          </div>
        </c11n-card>
      </div>
    </div>
  </div>
  <div class="flex-column main__plan-and-take-action">
    <div class="flex-column main__plan-and-take-action-header">
      <h3 class="flex-row main__steps--title">
        <span class="flex-row">2</span> Consider rebalancing actions
      </h3>
      <p class="main__plan-and-take-action-description">
        There are different actions — and orders of actions — you can take to rebalance your portfolio, if needed. Review these approaches to make a plan that’s right for you. **
      </p>
    </div>
    <div>
      <phc-use-available-cash-accordion aria-label="Use available cash accordion"></phc-use-available-cash-accordion>
      <phc-sell-accordion aria-label="Sell accordion"></phc-sell-accordion>
      <phc-contribute-accordion aria-label="Contribute accordion"></phc-contribute-accordion>
    </div>
    <c11n-card>
      <div cardBody class="flex-row main__plan-and-take-action--card-body">
        <phc-take-action-banner-image-component aria-hidden="true"></phc-take-action-banner-image-component>
        <div class="flex-row main__plan-and-take-action--card-body-description">
          <div class="flex-column">
            <h4>Ready to take action?</h4>
            <p>If you already know what changes you want to make to get aligned to your targets, head to your holdings
              page to start making updates.</p>
          </div>
          <c11n-button cardAction #cardAction [ariaLabelText]="'Review my holdings'" [href]="urls.reviewMyHoldings"
            [iconName]="'link-right'" [iconPosition]="'trailing'" [labelText]="'Review my holdings'"
            [size]="'small'"></c11n-button>
        </div>
      </div>
    </c11n-card>
  </div>
  <div class="flex-column main__check-back-in">
    <h3 class="flex-row main__steps--title">
      <span class="flex-row">3</span> Check back in
    </h3>
    <p class="main__check-back-in--content-description">
      After any trades have settled, revisit this page to check the status of your diversification and rebalance again if needed. ***
    </p>
    <a c11n-link [href]="urls.orderStatus" target="_blank" [iconName]="'link-right'" [variant]="'secondary-reinforced'">
      Review order status
    </a>
  </div>
  <div class="main__disclosure-box">
    <phc-disclosure-box [tag]="'rebalance'"></phc-disclosure-box>
  </div>
</div>