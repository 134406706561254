<phc-accordion aria-label="Contribute, then buy">
  <header>
    <p class="plan-and-take-action--accordion-header">
      <b>Contribute, then buy:</b> Take advantage of the power of compounding
    </p>
  </header>
  <content>
    <div class="plan-and-take-action-accordion-content">
      <ol>
        <li class="plan-and-take-action-accordion-content-item">Make your contribution</li>
        <ul class="plan-and-take-action-accordion-content--text">
          <li class="plan-and-take-action-accordion-content--text-item">
            Check that you haven’t reached your contribution limit for the year
          </li>
          <li>Make a cash transfer from your bank to the settlement fund in your IRA</li>
          <li>
            <a
              c11n-link
              [href]="urls.makeAContribuition"
              target="_blank"
              [variant]="'secondary-independent'"
              aria-label="Make a contribution"
              >Make a contribution</a
            >
          </li>
        </ul>
        <li class="plan-and-take-action-accordion-content-item">Determine what to buy</li>
        <ul class="plan-and-take-action-accordion-content--text">
          <li class="plan-and-take-action-accordion-content--text-item">
            Compare the stock, bond, and cash asset type percentages with your targets
          </li>
          <li>Determine the differences between the current and target allocations</li>
          <li>
            Using the money you just contributed to your account, buy assets you have less of to
            better align to your targets
          </li>
        </ul>
        <li class="plan-and-take-action-accordion-content-item">Invest your cash</li>
      </ol>
      <p>
        Need more stocks? Either buy more of the stocks you already own or buy new stock
        investments.
      </p>
      <ul class="plan-and-take-action-accordion-content--text-link">
        <li>
          <a
            c11n-link
            [href]="urls.reviewMyStocksReviewMyBonds"
            target="_blank"
            [variant]="'secondary-independent'"
            aria-label="Review my stocks"
            >Review my stocks</a
          >
        </li>
        <li>
          <a
            c11n-link
            [href]="urls.allStockFunds"
            target="_blank"
            [variant]="'secondary-independent'"
            aria-label="View all stock funds"
            >View all stock funds</a
          >
        </li>
      </ul>
      <p>
        Need more bonds? Either buy more of the bonds you already own or buy new bond investments.
      </p>
      <ul class="plan-and-take-action-accordion-content--text-link">
        <li>
          <a
            c11n-link
            [href]="urls.reviewMyStocksReviewMyBonds"
            target="_blank"
            [variant]="'secondary-independent'"
            aria-label="Review my bonds"
            >Review my bonds</a
          >
        </li>
        <li>
          <a
            c11n-link
            [href]="urls.allBondsFunds"
            target="_blank"
            [variant]="'secondary-independent'"
            aria-label="View all bond funds"
            >View all bond funds</a
          >
        </li>
        <li>
          <a
            c11n-link
            [href]="urls.allBonds"
            target="_blank"
            [variant]="'secondary-independent'"
            aria-label="View all bonds"
            >View all bonds</a
          >
        </li>
      </ul>
    </div>
  </content>
</phc-accordion>
