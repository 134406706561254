<div id="appHeaderWrapper" class="app-header-wrapper" #content="pwContent" pwContent>
  <div class="secondary-cta-wrapper linkWrapper" *ngIf="!isUserInMainPage">
    <div class="app-nav-link c11n-text-md" *ngIf="!router.url.includes(phcUrls.phc)" >
      <a
        c11n-link
        (click)="navigateBackToPortfolioWatch()"
        (keypress)="navigateBackToPortfolioWatch()"
        [fontWeight]="'bold'"
        [noWrap]="true"
        [iconName]="'link-left'"
        [iconPosition]="'leading'"
        [ariaLabel]="content['PortfolioTester_BackToPortfolioWatch'] | getFromAEM"
        tabindex="0"
        class="button-cta"
      >
        <span [innerHTML]="content['PortfolioTester_BackToPortfolioWatch'] | getFromAEM"></span>
      </a>
    </div>

    <div class="hypothetical-button-wrapper" *ngIf="router.url.startsWith('/hypothetical')">
      <c11n-button
        class="hypothetical-button"
        [labelText]="content['TesterTool_HypotheticalOverview_CloseScenario'] | getFromAEM"
        (clickEvent)="navigateBackToPortfolioWatch()"
        (keypress)="navigateBackToPortfolioWatch()"
      ></c11n-button>
      <c11n-button
        class="hypothetical-button"
        [labelText]="content['TesterTool_HypotheticalOverview_EditScenario'] | getFromAEM"
        (clickEvent)="navigateToTesterPage()"
      ></c11n-button>
    </div>
  </div>

  <div
    id="primaryCTAWrapperExp"
    class="primary-cta-wrapper-exp"
    *ngIf="!router.url.includes('portfolio-health-check') && (($isFailure | async) === false); else pwFailureBannerTitle"
  >
    <div
      class="page-title"
      *ngIf="!router.url.includes('tester-tool') && ($isLoading | async) !== true && !router.url.includes(phcUrls.phc)"
    >
      <ss-panel-text
        *ngIf="!isEnableAEMGatekeeper"
        class="ss-panel-text--unstyled"
        [content]="
        router.url.includes('/hypothetical')
          ? (content['PortfolioTester_HypotheticalAnalysis'] | getFromAEM) + ' for ' + getDisplayName()
          : (content[pageTitle] | getFromAEM) + ' for ' + getDisplayName()
        "
        [placeholder]="(content[pageTitle] | getFromAEM) + ' for ' + getDisplayName()"
      ></ss-panel-text>
      <ss-panel-text
        *ngIf="isEnableAEMGatekeeper"
        class="ss-panel-text--unstyled"
        [content]="
        router.url.includes('/hypothetical')
          ? (content['PortfolioTester_HypotheticalAnalysis'] | getFromAEM: { 'selected-group-name': getDisplayName() })
          : (content[pageTitle] | getFromAEM: { 'selected-group-name': getDisplayName() })
        "
        [placeholder]="(content[pageTitle] | getFromAEM: { 'selected-group-name': getDisplayName() })"
      ></ss-panel-text>
    </div>
    <div class="panel-toggle-exp-wrapper"><ss-panel-toggle></ss-panel-toggle></div>
    <div
      class="tools-section-exp-wrapper"
      *ngIf="!(router.url.includes('/tester-tool') || router.url.startsWith('/hypothetical')) && !router.url.includes(phcUrls.phc)">
      <ng-template [ngTemplateOutlet]="toolsSectionNormal"></ng-template>
    </div>
  </div>

  <div id="primaryCTAWrapperExp" *ngIf="router.url.includes('portfolio-health-check') && (($isFailure | async) === false)">
    <div *ngIf="router.url.includes('rebalancing-your-portfolio')" class="rebalancing-your-portfolio-header phc-header">
      <div>
        <a c11n-link tabindex="0" (click)="navigateToPortfolioHealthCheckPage()" (keyup.enter)="navigateToPortfolioHealthCheckPage()" [iconName]="'link-left'" [iconPosition]="'leading'">
          Go back to overview
        </a>
      </div>
      <div class="tools-section-exp-wrapper">
        <ng-template [ngTemplateOutlet]="toolsSectionPHC"></ng-template>
      </div>
    </div>

    <div *ngIf="router.url.includes('portfolio-health-check') && !router.url.includes('rebalancing-your-portfolio')" class="tools-section-exp-wrapper phc-header">
      <ng-template [ngTemplateOutlet]="toolsSectionPHC"></ng-template>
    </div>
  </div>

  <ng-template #pwFailureBannerTitle>
    <div class="page-title" *ngIf="!router.url.includes('portfolio-health-check') && !router.url.includes('tester-tool') && ($isLoading | async) !== true">
      <span>{{ content[pageTitle] | getFromAEM: { 'selected-group-name': getDisplayName() } }} </span>
    </div>
  </ng-template>

  <div class="row">
    <div class="overview-content">
      <div *ngIf="!router.url.includes('/tester-tool') && !router.url.includes(phcUrls.phc)" class="overview">
        <div class="disclaimer" *ngIf="customizedAccountsView">
          <p class="c11n-text-md c11n-link--reinforced">
            {{ content['PWHeader_CustomizedAccountsDisclaimer_1'] | getFromAEM }}
            <a
              c11n-link
              [href]="modernizedCustomizeView"
              [variant]="'secondary-reinforced'"
              [innerHtml]="content['PWHeader_CustomizedAccountsDisclaimer_CustomLink'] | getFromAEM">
            </a>
            {{ content['PWHeader_CustomizedAccountsDisclaimer_2'] | getFromAEM }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <ng-template #toolsSectionNormal>
    <div id="headerToolsSection" class="tools-section-normal" *ngIf="!router.url.startsWith('/hypothetical') && !router.url.includes(phcUrls.noGoals)">
      <a c11n-link id="pageHelpLink" tabindex="0" (click)="openInlineDrawerPageHelp()"
        [variant]="'secondary-independent'" [fontWeight]="'normal'" (keyup.enter)="openInlineDrawerPageHelp()"
        [attr.aria-label]="content['PWOverview_Help'] | getFromAEM" [iconName]="'help'" class="button-cta">{{
        content['PWOverview_Help'] | getFromAEM }}
      </a>
      <a c11n-link id="printLink" target="_blank" tabindex="0" (click)="print(); printLinkClicked.emit(defaultData)"
        [variant]="'secondary-independent'" [fontWeight]="'normal'"
        (keyup.enter)="print(); printLinkClicked.emit(defaultData)" class="button-cta">{{ content['PWOverview_Print'] |
        getFromAEM }}
      </a>
      <a *ngIf="!router.url.includes(phcUrls.phc)" c11n-link tabindex="0" (click)="navigateToTesterPage()" [variant]="'secondary-independent'"
        [fontWeight]="'normal'" (keyup.enter)="navigateToTesterPage()" class="button-cta">{{
        content['PWOverview_PortfolioTester'] | getFromAEM }}
      </a>
    </div>
  </ng-template>

  <ng-template #toolsSectionPHC>
    <div id="headerToolsSectionPHC" class="tools-section-phc phc-header">
      <div class="tools-section-phc--hidden"></div>
      <a c11n-link id="printLink" target="_blank" tabindex="0" (click)="print(); printLinkClicked.emit(defaultData)"
        [variant]="'secondary-independent'" [fontWeight]="'normal'"
        (keyup.enter)="print(); printLinkClicked.emit(defaultData)" class="button-cta">{{ content['PWOverview_Print'] |
        getFromAEM }}
      </a>
      <a c11n-link id="pageHelpLink" tabindex="0" (click)="openPageHelpDrawer('aboutPortfolioWatch')"
        [variant]="'secondary-independent'" [fontWeight]="'normal'" (keyup.enter)="openPageHelpDrawer('aboutPortfolioWatch')"
        [attr.aria-label]="content['PWOverview_Help'] | getFromAEM" [iconName]="'help'" class="button-cta">{{
        content['PWOverview_Help'] | getFromAEM }}
      </a>
      <a *ngIf="!router.url.includes(phcUrls.phc)" c11n-link tabindex="0" (click)="navigateToTesterPage()" [variant]="'secondary-independent'"
        [fontWeight]="'normal'" (keyup.enter)="navigateToTesterPage()" class="button-cta">{{
        content['PWOverview_PortfolioTester'] | getFromAEM }}
      </a>
    </div>
  </ng-template>
</div>