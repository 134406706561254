import { Component, Input } from '@angular/core';
import { PhcUrl } from '../../../../app/models/interfaces/PhcData';
import { getPHCUrls } from '../../../../app/utils/functions';

type DisclosureBoxTag = 'no-goals' | 'rebalance' | 'portfolio'

@Component({
  selector: 'phc-disclosure-box',
  templateUrl: './disclosure-box.component.html',
  styleUrls: ['./disclosure-box.component.scss']
})
export class DisclosureBoxComponent  {

  urls: PhcUrl = {}

  @Input() tag: DisclosureBoxTag = 'no-goals';

  constructor() {
    this.urls = getPHCUrls()
  }

  reusableParagraphs: string[] = [
    '*BETA is a version released to a select group of users for testing and feedback. Before the BETA release, the Portfolio Watch page showed data for all your accounts. Now, it only shows data for accounts (with the exception of any outside accounts) linked to your retirement goal from your Goal Planner inputs.',
    'All investing is subject to risk, including possible loss of the money you invest. This information is general and educational in nature and should not be considered tax or legal advice.',
    'You should consult a tax or financial advisor about your individual situation.',
    'Diversification does not ensure a profit or protect against loss.',
    'There is no guarantee that any particular asset allocation or mix of funds will meet your investment objectives or provide you with a given level of income.',
    'Vanguard Marketing Corporation ("VMC") may use this information to provide you with personalized guidance such as education or financial planning tools, however, this information is not used to monitor your accounts.'
  ];

  textNoGoals: string[] = [
    ...this.reusableParagraphs
  ];

  textPortifolio: string[] = [
    ...this.reusableParagraphs.slice(0, 2),
    'This tool is designed to help you keep a balanced and diversified mix of investments relative to your goals aligned with Vanguard\'s four principles for investment success.',
    'The tool provides a qualitative indicator on the level of diversification across overall asset mix, global stock exposure, and single stock concentration. In the background the tool scores your current portfolio using methodology based on the Vanguard Asset Allocation Model (VAMM) relative to a target asset allocation based on your Goal Planner inputs. In assessing whether your portfolio is less diversified, moderately diversified, or well diversified, your overall asset mix is more heavily weighted than global stock exposure. The presence of individual stock securities penalizes the diversification assessment.',
    'This tool does not analyze other investment risks of your current holdings (e.g. active vs index investment strategy risks or manager risk).',
    'Like any model, the VAAM has its assumptions, limitations, and measurement imprecisions. It relies on the Vanguard Capital Markets Model (VCMM) forecasts, which are based on historical data but also include forward-looking return assumptions and assumptions about asset-class risk characteristics. The accuracy of these VCMM forecasts, and thus the optimal weights the VAAM calculates, can be compromised if historical data do not accurately predict future conditions.',
    'IMPORTANT: The projections and other information generated by the VCMM regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results, and are not guarantees of future results. VCMM results will vary with each use and over time.',
    'Your target asset allocation is determined using your Goal Planner inputs and the Vanguard Life Cycle Investing Model (VLCM). VLCM is a glide-path construction model that can assist in the creation of custom investment portfolios for retirement as well as nonretirement goals, such as saving for college.',
    ...this.reusableParagraphs.slice(2)
  ];

  textRebalance: string[] = [
    '*Your specific global stock exposure target range for international equities is based on your specific target asset allocation that you established within the Goal Planner tool and Vanguard\'s research using Vanguard Asset Allocation Model to help ensure a balanced and diversified portfolio.',
    '**This tool only assesses certain diversification factors based on asset allocation and sub-asset allocation. You should carefully consider investment objectives, investment risks, charges, expenses when considering specific investment decisions.',
    '***When it is time to rebalance carefully consider potential tax consequences as you evaluate your options.',
    '****Your target asset allocation is determined using your Goal Planner inputs and the Vanguard Life Cycle Investing Model (VLCM). VLCM is a glide-path construction model that can assist in the creation of custom investment portfolios for retirement as well as nonretirement goals, such as saving for college.',
    `This tool is designed to help you keep a balanced and diversified mix of investments relative to your goals aligned with Vanguard's four principles for investment success.`,
    'The tool provides a qualitative indicator on the level of diversification across overall asset mix, global stock exposure, and single stock concentration. In the background the tool scores your current portfolio using methodology based on the Vanguard Asset Allocation Model (VAMM) relative to a target asset allocation based on your Goal Planner inputs. In assessing whether your portfolio is less diversified, moderately diversified, or well diversified, your overall asset mix is more heavily weighted than global stock exposure. The presence of individual stock securities penalizes the diversification assessment.',
    'This tool does not analyze other investment risks of your current holdings (e.g. active vs index investment strategy risks or manager risk).',
    'Like any model, the VAAM has its assumptions, limitations, and measurement imprecisions. It relies on the Vanguard Capital Markets Model (VCMM) forecasts, which are based on historical data but also include forward-looking return assumptions and assumptions about asset-class risk characteristics. The accuracy of these VCMM forecasts, and thus the optimal weights the VAAM calculates, can be compromised if historical data do not accurately predict future conditions.',
    'IMPORTANT: The projections and other information generated by the VCMM regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results, and are not guarantees of future results. VCMM results will vary with each use and over time.',
    'Investments in bonds are subject to interest rate, credit, and inflation risk.',
    'There are additional risks when investing outside the United States, including the possibility that returns will be hurt by a decline in the value of foreign currencies or by unfavorable developments in a particular country or region.',
    ...this.reusableParagraphs.slice(1)
  ];

  getText(): string[] {
    if (this.tag === 'no-goals') {
      return this.textNoGoals;
    } else if (this.tag === 'portfolio') {
      return this.textPortifolio;
    } else {
      return this.textRebalance;
    }
  }
  
}
