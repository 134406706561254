import { PhcUrls } from "../../../app/models/interfaces/PhcData";

export const phcURLs: PhcUrls = {
    production: {
        external: {
            setGoals: "https://goals.vanguard.com/setup/financial-profile", //PROD EXTERNAL
            goalPlaner: "https://goals.vanguard.com/goal-planning/summary", //PROD EXTERNAL
            vanguardDotCom: "https://investor.vanguard.com/", //PROD EXTERNAL
            finra: "https://www.finra.org/investors", //EXTERNAL LINK
            sipc: "https://www.sipc.org/", //EXTERNAL LINK
            orderStatus: "https://order-status-v2.cdn.web.vanguard.com/?qt=redirect", //PROD EXTERNAL
            allStockFunds: "https://investor.vanguard.com/investment-products/list/all?assetclass=equity&filters=open", //PROD EXTERNAL
            allBondsFunds: "https://investor.vanguard.com/investment-products/list/all?assetclass=fixed_income&filters=open", //PROD EXTERNAL
            allBonds: "https://fixed-income-trading.web.vanguard.com/", //PROD EXTERNAL
            reviewMyStocksReviewMyBonds: "https://holdings.web.vanguard.com/", //PROD EXTERNAL
            makeAContribuition: "https://personal.vanguard.com/us/buysell/BuyFunds", //PROD EXTERNAL
            rebalancePortfolio: "/portfolio-health-check/rebalancing-your-portfolio",
            noGoals: "/portfolio-health-check/no-goals",
            phc: "/portfolio-health-check",
            reviewMyHoldings: "https://holdings.web.vanguard.com/", //PROD EXTERNAL
        },
        internal: {
            setGoals: "https://goals.vanguard.com/setup/financial-profile", //PROD EXTERNAL
            goalPlaner: "https://goals.vanguard.com/goal-planning/summary", //PROD EXTERNAL
            vanguardDotCom: "https://investor.vanguard.com/", //PROD EXTERNAL
            finra: "https://www.finra.org/investors", //EXTERNAL LINK
            sipc: "https://www.sipc.org/", //EXTERNAL LINK
            orderStatus: "https://order-status-v2.cdn.web.vanguard.com/?qt=redirect", //PROD EXTERNAL
            allStockFunds: "https://investor.vanguard.com/investment-products/list/all?assetclass=equity&filters=open", //PROD EXTERNAL
            allBondsFunds: "https://investor.vanguard.com/investment-products/list/all?assetclass=fixed_income&filters=open", //PROD EXTERNAL
            allBonds: "https://fixed-income-trading.web.vanguard.com/", //PROD EXTERNAL
            reviewMyStocksReviewMyBonds: "https://holdings.web.vanguard.com/", //PROD EXTERNAL
            makeAContribuition: "https://personal.vanguard.com/us/buysell/BuyFunds", //PROD EXTERNAL
            rebalancePortfolio: "/portfolio-health-check/rebalancing-your-portfolio",
            noGoals: "/portfolio-health-check/no-goals",
            phc: "/portfolio-health-check",
            reviewMyHoldings: "https://holdings.web.vanguard.com/", //PROD EXTERNAL
        }
    },
    test: {
        external: {
            setGoals: "https://satgoals.vanguard.com/setup/financial-profile", //SAT EXTERNAL
            goalPlaner: "https://satgoals.vanguard.com/goal-planning/summary", //SAT EXTERNAL
            vanguardDotCom: "https://satinvestor.vanguard.com/", //SAT EXTERNAL
            finra: "https://www.finra.org/investors", //EXTERNAL LINK
            sipc: "https://www.sipc.org/", //EXTERNAL LINK
            orderStatus: "https://order-status-v2.cdn.webt.vanguard.com/?qt=redirect", //SAT EXTERNAL
            allStockFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=equity&filters=open", //SAT EXTERNAL
            allBondsFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=fixed_income&filters=open", //SAT EXTERNAL
            allBonds: "https://fixed-income-trading.webt.vanguard.com/", //SAT EXTERNAL
            reviewMyStocksReviewMyBonds: "https://holdings.webt.vanguard.com/", //SAT EXTERNAL
            makeAContribuition: "https://personal.vanguard.com/us/buysell/BuyFunds", //PROD EXTERNAL
            rebalancePortfolio: "/portfolio-health-check/rebalancing-your-portfolio",
            noGoals: "/portfolio-health-check/no-goals",
            phc: "/portfolio-health-check",
            reviewMyHoldings: "https://holdings.webt.vanguard.com/", //SAT EXTERNAL
        },
        internal: {
            setGoals: "https://satgoals.vanguard.com/setup/financial-profile", //SAT EXTERNAL
            goalPlaner: "https://satgoals.vanguard.com/goal-planning/summary", //SAT EXTERNAL
            vanguardDotCom: "https://satinvestor.vanguard.com/", //SAT EXTERNAL
            finra: "https://www.finra.org/investors", //EXTERNAL LINK
            sipc: "https://www.sipc.org/", //EXTERNAL LINK
            orderStatus: "https://order-status-v2.cdn.webt.vanguard.com/?qt=redirect", //SAT EXTERNAL
            allStockFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=equity&filters=open", //SAT EXTERNAL
            allBondsFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=fixed_income&filters=open", //SAT EXTERNAL
            allBonds: "https://fixed-income-trading.webt.vanguard.com/", //SAT EXTERNAL
            reviewMyStocksReviewMyBonds: "https://holdings.webt.vanguard.com/", //SAT EXTERNAL
            makeAContribuition: "https://personal.vanguard.com/us/buysell/BuyFunds", //PROD EXTERNAL
            rebalancePortfolio: "/portfolio-health-check/rebalancing-your-portfolio",
            noGoals: "/portfolio-health-check/no-goals",
            phc: "/portfolio-health-check",
            reviewMyHoldings: "https://holdings-i.webt.vanguard.com/", //SAT INTERNAL
        }
    },
    development: {
        external: {
            setGoals: "https://satgoals.vanguard.com/setup/financial-profile", //SAT EXTERNAL
            goalPlaner: "https://satgoals.vanguard.com/goal-planning/summary", //SAT EXTERNAL
            vanguardDotCom: "https://satinvestor.vanguard.com/", //SAT EXTERNAL
            finra: "https://www.finra.org/investors", //EXTERNAL LINK
            sipc: "https://www.sipc.org/", //EXTERNAL LINK
            orderStatus: "https://order-status-v2.cdn.webt.vanguard.com/?qt=redirect", //SAT EXTERNAL
            allStockFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=equity&filters=open", //SAT EXTERNAL
            allBondsFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=fixed_income&filters=open", //SAT EXTERNAL
            allBonds: "https://fixed-income-trading.webt.vanguard.com/", //SAT EXTERNAL
            reviewMyStocksReviewMyBonds: "https://holdings.webt.vanguard.com/", //SAT EXTERNAL
            makeAContribuition: "https://personal.vanguard.com/us/buysell/BuyFunds", //PROD EXTERNAL
            rebalancePortfolio: "/portfolio-health-check/rebalancing-your-portfolio",
            noGoals: "/portfolio-health-check/no-goals",
            phc: "/portfolio-health-check",
            reviewMyHoldings: "https://holdings.webt.vanguard.com/", //SAT EXTERNAL
        },
        internal: {
            setGoals: "https://satgoals.vanguard.com/setup/financial-profile", //SAT EXTERNAL
            goalPlaner: "https://satgoals.vanguard.com/goal-planning/summary", //SAT EXTERNAL
            vanguardDotCom: "https://satinvestor.vanguard.com/", //SAT EXTERNAL
            finra: "https://www.finra.org/investors", //EXTERNAL LINK
            sipc: "https://www.sipc.org/", //EXTERNAL LINK
            orderStatus: "https://order-status-v2.cdn.webt.vanguard.com/?qt=redirect", //SAT EXTERNAL
            allStockFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=equity&filters=open", //SAT EXTERNAL
            allBondsFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=fixed_income&filters=open", //SAT EXTERNAL
            allBonds: "https://fixed-income-trading.webt.vanguard.com/", //SAT EXTERNAL
            reviewMyStocksReviewMyBonds: "https://holdings.webt.vanguard.com/", //SAT EXTERNAL
            makeAContribuition: "https://personal.vanguard.com/us/buysell/BuyFunds", //PROD EXTERNAL
            rebalancePortfolio: "/portfolio-health-check/rebalancing-your-portfolio",
            noGoals: "/portfolio-health-check/no-goals",
            phc: "/portfolio-health-check",
            reviewMyHoldings: "https://holdings-i.webt.vanguard.com/", //SAT INTERNAL
        }
    },
    local: {
        external: {
            setGoals: "https://satgoals.vanguard.com/setup/financial-profile", //SAT EXTERNAL
            goalPlaner: "https://satgoals.vanguard.com/goal-planning/summary", //SAT EXTERNAL
            vanguardDotCom: "https://satinvestor.vanguard.com/", //SAT EXTERNAL
            finra: "https://www.finra.org/investors", //EXTERNAL LINK
            sipc: "https://www.sipc.org/", //EXTERNAL LINK
            orderStatus: "https://order-status-v2.cdn.webt.vanguard.com/?qt=redirect", //SAT EXTERNAL
            allStockFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=equity&filters=open", //SAT EXTERNAL
            allBondsFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=fixed_income&filters=open", //SAT EXTERNAL
            allBonds: "https://fixed-income-trading.webt.vanguard.com/", //SAT EXTERNAL
            reviewMyStocksReviewMyBonds: "https://holdings.webt.vanguard.com/", //SAT EXTERNAL
            makeAContribuition: "https://personal.vanguard.com/us/buysell/BuyFunds", //PROD EXTERNAL
            rebalancePortfolio: "/portfolio-health-check/rebalancing-your-portfolio",
            noGoals: "/portfolio-health-check/no-goals",
            phc: "/portfolio-health-check",
            reviewMyHoldings: "https://holdings.webt.vanguard.com/", //SAT EXTERNAL
        },
        internal: {
            setGoals: "https://satgoals.vanguard.com/setup/financial-profile", //SAT EXTERNAL
            goalPlaner: "https://satgoals.vanguard.com/goal-planning/summary", //SAT EXTERNAL
            vanguardDotCom: "https://satinvestor.vanguard.com/", //SAT EXTERNAL
            finra: "https://www.finra.org/investors", //EXTERNAL LINK
            sipc: "https://www.sipc.org/", //EXTERNAL LINK
            orderStatus: "https://order-status-v2.cdn.webt.vanguard.com/?qt=redirect", //SAT EXTERNAL
            allStockFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=equity&filters=open", //SAT EXTERNAL
            allBondsFunds: "https://satinvestor.vanguard.com/investment-products/list/all?assetclass=fixed_income&filters=open", //SAT EXTERNAL
            allBonds: "https://fixed-income-trading.webt.vanguard.com/", //SAT EXTERNAL
            reviewMyStocksReviewMyBonds: "https://holdings.webt.vanguard.com/", //SAT EXTERNAL
            makeAContribuition: "https://personal.vanguard.com/us/buysell/BuyFunds", //PROD EXTERNAL
            rebalancePortfolio: "/portfolio-health-check/rebalancing-your-portfolio",
            noGoals: "/portfolio-health-check/no-goals",
            phc: "/portfolio-health-check",
            reviewMyHoldings: "https://holdings-i.webt.vanguard.com/", //SAT INTERNAL
        }
    }
}

