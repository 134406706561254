<c11n-card class="card">
  <div cardBody>
    <div class="flex-row card__image-content">
      <div style="flex: 1">
        <span id="beta" aria-label="Beta version">BETA*</span>
        <div class="cardImage">
          <phc-gauge
            [percent]="percent"
            [text]="text"
            aria-label="Gauge showing diversification level"
          ></phc-gauge>
        </div>
      </div>
      <div class="flex-column card__content">
        <h3>Your portfolio could be diversified more</h3>
        <p class="card__text">
          Having a diversified portfolio helps offset market volatility and can provide stability,
          while still allowing opportunity for long-term growth.
        </p>
      </div>
    </div>
    <p class="goalPlanner">
      Your diversification analysis is based on your Goal Planner inputs: your associated
      account(s), target retirement date, and risk tolerance. If you’d like to update your inputs,
      <span class="c11n-text-sm c11n-link--reinforced">
        <a
          c11n-link
          [href]="urls.goalPlaner"
          [variant]="'secondary-reinforced'"
          aria-label="Visit Goal Planner"
          >visit Goal Planner</a
        >.
      </span>
    </p>
  </div>
</c11n-card>
