import { Component, Input } from '@angular/core';

@Component({
  selector: 'phc-factor-line',
  templateUrl: './factor-line.component.html',
  styleUrls: ['./factor-line.component.scss']
})
export class FactorLineComponent {

  @Input() title: string;
  @Input() description: string;
  @Input() factorStatus: boolean;

}
