import { Component } from '@angular/core';
import { ModernizedStackedBarChartData } from 'src/app/models/chart-models/modernized-stacked-bar-chart';
import { Pw3ApiService } from '../../../services/portofolio-health-check/pw3-api.service';
import { PhcData, PhcUrl } from '../../../../app/models/interfaces/PhcData';
import { getPHCUrls } from '../../../../app/utils/functions';
import { PageHelpService } from 'src/app/components/page-help/page-help.component.service';

@Component({
  selector: 'PW1-rebalancing-your-portfolio',
  templateUrl: './rebalancing-your-portfolio.component.html',
  styleUrls: ['./rebalancing-your-portfolio.component.scss']
})
export class RebalancingYourPortfolioComponent {

  actualChartDataAssetMix: ModernizedStackedBarChartData;
  targetChartDataAssetMix: ModernizedStackedBarChartData;
  chartDataGlobalStock: ModernizedStackedBarChartData;

  data: PhcData

  assetMixAboveTarget: string = ''
  assetMixBelowTarget: string = ''
  assetMixAddingMore: string = ''
  globalStockOverUnder: string = ''
  globalStockAddingMore: string = ''

  urls: PhcUrl = {}

  wordsToSentenceReducer = (prev, curr, ind, arr) => {
    if (!prev) {
      return curr
    }
    if (ind === arr.length - 1) {
      prev += ` and ${curr}`
      return prev
    }
    prev += `, ${curr}`
    return prev
  }

  constructor(
    private pw3Service: Pw3ApiService,
    public readonly pageHelpService: PageHelpService,
  ) {
    this.pw3Service.getDataPayload().subscribe((data) => {
      if (this.pw3Service.isLoading.value) return

      this.data = data;
      const { portfolioFactors: { assetMix, domesticStock } } = this.data

      this.actualChartDataAssetMix = {
        chartId: "actualChartDataAssetMix",
        chartLabel: "Current",
        chartSlices: [
          {
            color: "#1ca29c",
            label: "Stocks",
            name: "stocks",
            value: assetMix.current.stocks
          },
          {
            color: "#bf8200",
            label: "Bonds",
            name: "bonds",
            value: assetMix.current.bonds
          },
          {
            color: "#717777",
            label: "Cash",
            name: "cash",
            value: assetMix.current.cash
          },
        ],
        usesMasks: false
      }
      this.targetChartDataAssetMix = {
        chartId: "targetChartDataAssetMix",
        chartLabel: "Target",
        chartSlices: [
          {
            color: "#1ca29c",
            label: "Stocks",
            name: "stocks",
            value: assetMix.target.stocks
          },
          {
            color: "#bf8200",
            label: "Bonds",
            name: "bonds",
            value: assetMix.target.bonds
          },
          {
            color: "#717777",
            label: "Cash",
            name: "cash",
            value: assetMix.target.cash
          },
        ],
        usesMasks: true
      }

      const assetMixAboveTarget = []
      const assetMixBelowTarget = []
      const assetMixAddingMore = []

      if (assetMix.current.stocks > assetMix.target.stocks) {
        assetMixAboveTarget.push('stocks')
        assetMixAddingMore.push('bonds')
      }
      if (assetMix.current.bonds > assetMix.target.bonds) {
        assetMixAboveTarget.push('bonds')
        assetMixAddingMore.push('stocks')
      }
      if (assetMix.current.cash > assetMix.target.cash) {
        assetMixAboveTarget.push('cash')
      }

      if (assetMix.current.stocks < assetMix.target.stocks) {
        assetMixBelowTarget.push('stocks')
        assetMixAddingMore.push('stocks')
      }
      if (assetMix.current.bonds < assetMix.target.bonds) {
        assetMixBelowTarget.push('bonds')
        assetMixAddingMore.push('bonds')
      }
      if (assetMix.current.cash < assetMix.target.cash) {
        assetMixBelowTarget.push('cash')
      }

      this.assetMixAboveTarget = assetMixAboveTarget.reduce(this.wordsToSentenceReducer, '')
      if (this.assetMixAboveTarget) {
        this.assetMixAboveTarget = `more ${this.assetMixAboveTarget}`
      }
      this.assetMixBelowTarget = assetMixBelowTarget.reduce(this.wordsToSentenceReducer, '')
      if (this.assetMixBelowTarget) {
        this.assetMixBelowTarget = `fewer ${this.assetMixBelowTarget}`
      }
      this.assetMixAddingMore = assetMixAddingMore.reduce(this.wordsToSentenceReducer, '')

      this.chartDataGlobalStock = {
        chartId: "chartDataGlobalStock",
        chartLabel: null,
        chartSlices: [
          {
            color: "#8DCFCB",
            label: "International stocks",
            name: "bonds",
            value: domesticStock.data.international.current,
            range: domesticStock.data.international.range
          }, {
            color: "#1A9E97",
            label: "Domestic (U.S.) stocks",
            name: "stocks",
            value: domesticStock.data.domestic.current,
            range: []
          },
        ],
        usesMasks: false
      }

      if (domesticStock.data.international.current > domesticStock.data.international.range[1]) {
        this.globalStockOverUnder = 'over'
        this.globalStockAddingMore = 'domestic'
      } else if (domesticStock.data.international.current < domesticStock.data.international.range[0]) {
        this.globalStockOverUnder = 'under'
        this.globalStockAddingMore = 'international'
      } else {
        this.globalStockAddingMore = 'domestic'
      }

    })

    this.urls = getPHCUrls()
  }

  openPageHelpDrawer(type: string) {
    this.pageHelpService.setCurrentDrawerType(type);
    this.pageHelpService.togglePageHelpDrawer();
  }

}
