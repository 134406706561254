import { Component } from '@angular/core';
import { PhcUrl } from '../../../../../app/models/interfaces/PhcData';
import { getPHCUrls } from '../../../../../app/utils/functions';

@Component({
  selector: 'phc-use-available-cash-accordion',
  templateUrl: './use-available-cash-accordion.component.html',
  styleUrls: ['./use-available-cash-accordion.component.scss']
})
export class UseAvailableCashAccordionComponent {

  urls: PhcUrl = {}

  constructor() {
    this.urls = getPHCUrls()
  }



}
