import { Component, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { DrawerComponent } from '@vg-constellation/angular-18/drawer';
import { PageHelpService } from './page-help.component.service';
import pageHelp from './page-help-content/phc-page-help';

@Component({
  selector: 'page-help',
  templateUrl: './page-help.component.html',
  styleUrls: ['./page-help.component.scss']
})
export class PageHelpComponent implements AfterViewInit {
  readonly CLASSNAME_FOCUS_PANEL_ACTIVE = 'ss-panel--active';
  isFPOpen: boolean = false;
  isPhcPage: boolean = false;
  isPageHelpOpen: boolean = false;

  @ViewChild('pageHelpDrawer') pageHelpDrawer!: DrawerComponent;
  public pageHelp;
  private hasInitialLoadCompleted = false;

  @Output() drawerStateChange = new EventEmitter<{ isDrawerOpen: boolean }>();

  constructor(
    public pageHelpService: PageHelpService,
  ) {
    // this.isPhcPage = window.location.href.includes('portfolio-health-check');
  }

  ngAfterViewInit() {
    this.pageHelpService.currentDrawerStatus$.subscribe((shouldOpen) => {
      if (this.hasInitialLoadCompleted) {
        if (shouldOpen) {
          this.isPageHelpOpen = true;
          this.pageHelp = pageHelp[`${this.pageHelpService.currentDrawerType$.value}`];
          this.pageHelpDrawer.openDrawer();
        } else {
          this.isPageHelpOpen = false;
          this.pageHelp = null;
          this.pageHelpDrawer.closeDrawer();
        }
      } else {
        this.hasInitialLoadCompleted = true;
      }
    });
  }

  onClickClose() {
    this.pageHelpService.togglePageHelpDrawer();
  }

  drawerStateHandler(event) {
    this.drawerStateChange.emit({ isDrawerOpen: event?.isDrawerOpen });
  }
}
