<svg width="100%" height="100%" viewBox="0 0 392 166" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2422_53869)">
    <mask id="path-1-inside-1_2422_53869" fill="white">
      <path d="M0 0.0740967H392V166H0V0.0740967Z" />
    </mask>
    <path
      d="M201.531 32.3886V6.75333C235.706 8.35542 266.628 22.395 289.875 44.4485L271.625 62.5956C253.048 45.2016 228.577 34.0255 201.531 32.3886Z"
      fill="#00BDA3"
      stroke="black"
      stroke-width="0.691358"
    />
    <path
      d="M279.567 70.701L297.801 52.4894C319.622 76.887 332.889 109.096 332.889 144.404C332.889 218.609 274.288 279.128 200.839 282.207V257.272C260.183 254.042 307.309 204.899 307.309 144.749C307.309 116.405 296.844 90.5043 279.567 70.701Z"
      fill="#007873"
      stroke="black"
      stroke-width="0.691358"
    />
    <path
      d="M195.659 257.562L195.995 282.451C119.982 282.262 58.4197 220.583 58.4197 144.526C58.4197 70.5529 116.653 10.1812 189.778 6.75326V32.3488C130.434 35.5783 83.3086 84.7213 83.3086 144.871C83.3086 206.995 133.578 257.378 195.659 257.562Z"
      fill="#DEF5F0"
      stroke="black"
      stroke-width="0.691358"
    />
    <path
      d="M280.374 146.348C280.374 192.536 242.931 229.979 196.743 229.979C150.537 229.979 110.346 192.518 110.346 146.348C110.346 100.177 150.537 62.7167 196.743 62.7167C242.931 62.7167 280.374 100.16 280.374 146.348Z"
      fill="#F5F6F6"
      stroke="black"
      stroke-width="0.840512"
    />
    <path
      d="M240.158 24.5046L211.657 148.259C210.248 154.376 206.725 160.424 200.467 160.921C198.137 161.106 195.439 160.88 192.471 159.868C189.642 158.902 187.443 157.52 185.744 156.032C180.873 151.765 181.877 144.573 184.705 138.747L240.158 24.5046Z"
      fill="black"
    />
    <circle
      cx="197.244"
      cy="146.688"
      r="10.3704"
      transform="rotate(19 197.244 146.688)"
      fill="white"
    />
    <path
      d="M30.5889 46.3735C32.6216 46.3735 34.2694 44.7257 34.2694 42.6931C34.2694 40.6605 32.6216 39.0127 30.5889 39.0127C28.5563 39.0127 26.9085 40.6605 26.9085 42.6931C26.9085 44.7257 28.5563 46.3735 30.5889 46.3735Z"
      fill="#FFAD00"
      stroke="#231F20"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M24.7003 17.2457C24.7003 17.2457 33.8488 19.9797 35.6364 31.6519C37.424 20.0849 46.5725 17.2457 46.5725 17.2457C46.5725 17.2457 37.3189 14.4065 35.6364 2.83954C33.8488 14.4065 24.7003 17.2457 24.7003 17.2457Z"
      fill="#FFAD00"
      stroke="#231F20"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M8.29623 54.3649C8.29623 54.3649 13.7643 56.0474 14.8158 62.9876C15.8674 56.0474 21.3354 54.3649 21.3354 54.3649C21.3354 54.3649 15.8674 52.6824 14.8158 45.7422C13.7643 52.6824 8.29623 54.3649 8.29623 54.3649Z"
      fill="#FFAD00"
      stroke="#231F20"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M351.327 95.0242C353.301 95.0242 354.901 93.4241 354.901 91.4504C354.901 89.4766 353.301 87.8766 351.327 87.8766C349.353 87.8766 347.753 89.4766 347.753 91.4504C347.753 93.4241 349.353 95.0242 351.327 95.0242Z"
      fill="#FFAD00"
      stroke="#231F20"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M355.973 125.287C355.973 125.287 361.282 126.92 362.303 133.659C363.324 126.92 368.634 125.287 368.634 125.287C368.634 125.287 363.324 123.653 362.303 116.914C361.384 123.755 355.973 125.287 355.973 125.287Z"
      fill="#FFAD00"
      stroke="#231F20"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M359.43 101.174C359.43 101.174 368.313 103.829 370.049 115.163C371.785 103.931 380.668 101.174 380.668 101.174C380.668 101.174 371.785 98.5193 370.049 87.1852C368.313 98.5193 359.43 101.174 359.43 101.174Z"
      fill="#FFAD00"
      stroke="#231F20"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M82.4832 121.045C82.4832 121.045 86.7987 129.319 87.243 131.834C87.6966 134.354 87.9669 138.664 88.9518 142.714C89.8543 146.416 93.6979 152.014 93.6337 154.84C93.6291 155.101 93.5879 155.335 93.51 155.546C92.548 158.162 93.6291 165.995 93.6291 165.995H94.9577L95.9701 161.048C95.9701 161.048 97.4086 161.859 98.3065 163.476C99.2044 165.097 100.198 165.995 100.198 165.995H108.376C108.376 165.995 108.284 165.276 107.208 164.827C106.603 164.58 105.581 163.517 104.532 162.111C103.712 161.02 102.878 159.715 102.21 158.423C100.679 155.454 98.8425 131.651 98.3019 129.223C97.7659 126.795 96.4145 119.781 96.4145 119.781L82.4832 121.045Z"
      fill="#6C6C6C"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M80.4264 53.0889C81.5991 54.8756 84.2791 61.422 82.3917 62.2833C82.0848 62.4207 80.9761 63.0071 80.5317 63.2041C80.5317 63.2041 80.8341 64.2119 81.535 65.7283C81.8969 66.5116 83.6561 68.1334 83.6561 68.1334L72.6385 70.1124C72.6385 70.1124 74.2968 68.1288 74.5534 65.6275C74.8741 62.4894 71.6352 57.6426 71.2046 57.9541L70.806 55.4528C70.806 55.4528 74.1731 51.9345 74.59 51.9528C75.0069 51.9712 80.4264 53.0889 80.4264 53.0889Z"
      fill="#6C6C6C"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M62.34 166H70.1646C70.1646 166 71.1541 164.649 71.603 163.48C72.0474 162.312 73.2156 160.787 73.2156 160.787L74.2051 166H75.9139C75.9139 166 75.8223 161.236 75.6436 158.427C75.5291 156.645 75.2725 155.509 75.0801 154.387C74.961 153.745 74.8694 153.1 74.8373 152.348C74.7457 150.291 76.1887 140.827 76.7247 137.588C77.2653 134.354 76.7247 130.304 76.7247 128.329C76.7247 126.355 77.8929 121.852 77.8929 121.852L65.4002 121.132C65.4002 121.132 65.304 125.31 65.6705 127.963C66.0324 130.62 67.9198 151.162 68.0985 153.498C68.2268 155.266 66.5134 159.454 64.965 162.014C64.4702 162.834 63.9938 163.49 63.5998 163.838C61.9827 165.281 62.34 166 62.34 166Z"
      fill="#6C6C6C"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M68.0344 88.3774C67.7733 88.5836 65.2445 92.0744 64.8093 92.8165C64.3741 93.5587 61.4925 99.3996 60.361 101.319C59.2249 103.239 54.7674 110.271 54.7674 110.271C54.7674 110.271 53.9062 114.49 53.6451 115.534C53.3839 116.579 52.5089 118.324 52.5089 118.324C52.5089 118.324 51.9867 117.715 51.9867 116.753C51.9867 115.795 51.9867 114.485 51.9867 114.485C51.9867 114.485 49.7878 116.281 49.2609 117.5C48.7341 118.718 48.3218 120.212 47.7079 120.473C47.0986 120.734 46.9245 120.592 46.7505 119.717C46.5764 118.842 46.1412 118.759 46.4023 117.971C46.6634 117.188 46.4069 115.704 46.7505 115.182C47.094 114.659 47.9736 112.392 48.9311 111.081C49.8885 109.771 50.2413 108.727 50.8506 107.678C51.4599 106.633 54.9507 97.2098 55.56 96.1653C56.1693 95.1208 57.4062 91.6758 57.878 90.1457C58.3499 88.6156 59.7471 85.5234 59.7471 85.5234L68.0344 88.3774Z"
      fill="#6C6C6C"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M88.764 87.7039C88.764 87.7039 91.8563 93.7418 94.5729 105.74C97.2895 117.738 98.7967 124.454 98.7967 124.454L63.4808 125.361C63.4808 125.361 61.3643 107.435 64.988 98.7215C68.6117 90.0082 72.7576 88.7622 72.7576 88.7622L88.764 87.7039Z"
      fill="#F6001E"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M110.318 52.4661C110.318 52.4661 110.318 49.722 110.776 48.4301C111.152 47.3581 111.596 45.347 111.738 44.6782C111.757 44.5911 111.871 44.5682 111.917 44.6415C112.087 44.898 112.357 45.3974 112.371 45.9929C112.389 46.6847 112.394 48.0453 112.398 48.3385C112.398 48.3522 112.421 48.3614 112.426 48.3476L115.495 43.3725C115.587 43.2259 115.811 43.3313 115.756 43.4962L114.322 47.4452L114.615 47.5047L117.579 43.4687C117.712 43.2855 117.996 43.4596 117.891 43.6611L115.495 48.2789L115.761 48.5263L118.496 44.898C118.633 44.7148 118.917 44.8935 118.812 45.0996L116.265 50.258L116.503 50.4595C116.503 50.4595 119.201 47.1382 118.963 47.8849C118.725 48.6317 115.944 53.112 115.417 53.6159C114.56 54.4405 113.401 55.6545 113.401 55.6545L106.768 72.8567C105.856 75.2159 103.708 76.8651 101.193 77.14L94.6967 77.8409L95.3059 67.7854L101.628 68.0144L110.318 52.4661Z"
      fill="#6C6C6C"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M98.0408 66.979L97.3811 81.5195L91.2973 82.4449C91.2973 82.4449 91.2973 84.5614 90.2391 86.8107C89.1808 89.06 89.1808 89.06 89.1808 89.06C89.1808 89.06 70.6594 90.6497 70.2608 90.5168C69.8622 90.384 57.4611 86.5771 57.4611 86.5771C57.4611 86.5771 62.1568 74.9364 66.3897 72.4214C70.6227 69.9063 73.0415 69.2146 73.0415 69.2146L76.4407 68.3533C80.7653 67.2584 85.7679 66.7591 90.2208 66.9744H98.0408V66.979Z"
      fill="#040505"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M93.6291 166H94.9577L95.9701 161.052C95.9701 161.052 97.4086 161.863 98.3065 163.48C99.2044 165.102 100.198 166 100.198 166H108.376C108.376 166 108.284 165.281 107.208 164.832C106.603 164.584 105.581 163.522 104.532 162.115C103.437 161.996 102.155 161.817 101.367 161.597C99.7449 161.144 97.0467 156.114 95.4341 155.303C94.8523 155.014 94.2155 154.886 93.6383 154.845C93.6337 155.106 93.5925 155.34 93.5146 155.55C92.548 158.162 93.6291 166 93.6291 166Z"
      fill="#040505"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M62.3401 166H70.1647C70.1647 166 71.1542 164.648 71.6032 163.48C72.0475 162.312 73.2157 160.787 73.2157 160.787L74.2052 166H75.914C75.914 166 75.8224 161.236 75.6437 158.427C75.5292 156.645 75.2727 155.509 75.0802 154.387C73.7609 154.904 72.5332 155.94 71.5116 157.731C69.9815 160.411 68.6712 161.817 64.9697 162.024H64.9651C64.4704 162.844 63.9939 163.499 63.5999 163.847C61.9828 165.281 62.3401 166 62.3401 166Z"
      fill="#040505"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M75.8819 64.5555C74.8496 64.5555 74.0128 63.7187 74.0128 62.6864C74.0128 61.6541 74.8496 60.8173 75.8819 60.8173C76.9142 60.8173 77.751 61.6541 77.751 62.6864C77.751 63.7187 76.9142 64.5555 75.8819 64.5555Z"
      stroke="white"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M81.4846 54.61L75.9231 56.5112C75.9231 56.5112 75.749 57.6198 75.5154 57.913C75.5154 57.913 74.7366 57.4457 74.1273 58.0825C73.5134 58.7193 74.842 60.57 75.5016 62.6682C75.9781 64.18 75.9918 66.1178 75.9918 66.1178C75.9918 66.1178 73.0966 66.9607 71.3008 66.9195C68.5063 66.8599 66.5272 65.9437 66.5272 65.9437C66.5272 65.9437 69.0652 62.4116 69.1064 59.5118C69.1476 56.3875 69.4225 52.663 73.5776 51.4124C76.963 50.3954 79.533 51.2612 80.4035 52.3057C81.6495 53.7854 81.4846 54.61 81.4846 54.61Z"
      fill="#040505"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M75.4146 47.7566L74.6037 36.7161"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M70.0776 49.6578L63.6549 38.6586"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
    <path
      d="M65.7347 53.0935L55.331 48.21"
      stroke="#040505"
      stroke-width="0.691358"
      stroke-miterlimit="10"
    />
  </g>
  <path d="M392 165H0V167H392V165Z" fill="black" mask="url(#path-1-inside-1_2422_53869)" />
  <defs>
    <clipPath id="clip0_2422_53869">
      <path d="M0 0.0740967H392V166H0V0.0740967Z" fill="white" />
    </clipPath>
  </defs>
</svg>
