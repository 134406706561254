<phc-accordion aria-label="Sell, then buy">
  <header>
    <p class="plan-and-take-action--accordion-header"><b>Sell, then buy:</b> Manage risk by selling excess investments</p>
  </header>
  <content>
    <div class="plan-and-take-action-accordion-content">
      <ol>
        <li class="plan-and-take-action-accordion-content-item">Determine what to sell</li>
        <ul class="plan-and-take-action-accordion-content--text">
          <li class="plan-and-take-action-accordion-content--text-item">Compare the stock and bond asset type percentages to your targets</li>
          <li>Determine the difference between the current and target allocations</li>
          <li>Sell assets that you have too much of to align to your targets</li>
        </ul>
        <li class="plan-and-take-action-accordion-content-item">Invest your proceeds</li>
      </ol>
      <p>
        Need more stocks? Either sell bonds you already own and buy more of the stocks you already own, or buy new stock investments.
      </p>
      <ul class="plan-and-take-action-accordion-content--text-link">
        <li><a c11n-link [href]="urls.reviewMyStocksReviewMyBonds" target="_blank" [variant]="'secondary-independent'" aria-label="Review my bonds and stocks">Review my bonds and stocks</a></li>
        <li><a c11n-link [href]="urls.allStockFunds" target="_blank" [variant]="'secondary-independent'" aria-label="View all stock funds">View all stock funds</a></li>
      </ul>
      <p>
        Need more bonds? Either sell stocks you already own and buy more bonds you already own, or buy new bond investments.
      </p>
      <ul class="plan-and-take-action-accordion-content--text-link">
        <li><a c11n-link [href]="urls.reviewMyStocksReviewMyBonds" target="_blank" [variant]="'secondary-independent'" aria-label="Review my stocks and bonds">Review my stocks and bonds</a></li>
        <li><a c11n-link [href]="urls.allBondsFunds" target="_blank" [variant]="'secondary-independent'" aria-label="View all bond funds">View all bond funds</a></li>
        <li><a c11n-link [href]="urls.allBonds" target="_blank" [variant]="'secondary-independent'" aria-label="View all bonds">View all bonds</a></li>
      </ul>
    </div>
  </content>
</phc-accordion>