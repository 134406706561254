import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ChannelBrokerService } from '../services/portofolio-health-check/channel-broker.service';
import { Pw3ApiService } from '../services/portofolio-health-check/pw3-api.service';
import { PhcUrl } from '../models/interfaces/PhcData';
import { getPHCUrls } from '../utils/functions';
import { PortfolioDataService } from '../services/portfolio-data-service/portfolio-data.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioHealthCheckGuard implements CanActivate, CanActivateChild {

  private userHasGoals: boolean = false;
  phcUrls: PhcUrl = {}

  constructor(
    private readonly channelBrokerService: ChannelBrokerService,
    private readonly router: Router,
    private readonly pw3ApiService: Pw3ApiService,
    private readonly portfolioDataService: PortfolioDataService,
  ) {
    this.phcUrls = getPHCUrls()
    this.pw3ApiService.getDataPayload().subscribe({
      next: (data) =>  {
        if(!this.pw3ApiService.isLoading.value){
          if (data) {
            if (!!data?.diversifiedLevelText) {
              this.userHasGoals = true
              this.redirect(window.location.pathname);
              return
            }
          }
          this.userHasGoals = false
          this.redirect(window.location.pathname);
        }      
      },
      error: (err) => {
          console.error(err)
          this.pw3ApiService.setFailure(true)
          this.pw3ApiService.setLoading(false)
          this.portfolioDataService.setLoading(false)
          this.portfolioDataService.setFailure(true)
      }
    })
  }

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.redirect(state.url);
    return true;
  }

  canActivateChild(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.redirect(state.url);
    return true;
  }

  redirect(url: string) {
    this.channelBrokerService.isEligible$.subscribe({
      next: (isEligible: boolean) => {
        if (!isEligible) {
          if (this.isPHCLink(url)) {
            this.router.navigate(['']);
          }
        } else {
          if(!this.isPHCLink(url)){
            this.router.navigate([this.phcUrls.phc]);
          }
          if(!this.pw3ApiService.isLoading.value) {
            if (this.isUserHaveGoals()) {
              if (!this.isPHCLink(url) || this.isPHCNoGoalsLink(url)) {
                this.router.navigate([this.phcUrls.phc]);
              }
            } else {
              if (!this.isPHCNoGoalsLink(url)) {
                this.router.navigate([this.phcUrls.noGoals]);
              }
            }
          }
        }
      },
      error: (err) => {
        console.error('error', err)
        this.pw3ApiService.setFailure(true)
        this.pw3ApiService.setLoading(false)
        this.portfolioDataService.setFailure(true)
        this.portfolioDataService.setLoading(false)
      }
    });
  }

  isUserHaveGoals(): boolean {
    return this.userHasGoals;
  }

  isPHCLink(url: string): boolean {
    return url.startsWith(this.phcUrls.phc);
  }

  isPHCNoGoalsLink(url: string): boolean {
    return url === this.phcUrls.noGoals;
  }



}
