<ng-container>
    <div *ngIf="item.type === 'htmlTag'" [innerHTML]="item.content"></div>
    <div *ngIf="item.type === 'card'">
        <page-help-card [cardContent]="item.content"></page-help-card>
    </div>
    <ul *ngIf="item.type === 'unorderedList'">
        <li *ngFor="let i of item.content">
            <page-help-parser [item]="i"></page-help-parser>
        </li>
    </ul>
    <div *ngIf="item.type === 'link'" [innerHTML]="parseLinks(item)"></div>
    <div *ngIf="item.type === 'htmlTagWithLink'" [innerHTML]="parseLinks(item)"></div>
</ng-container>