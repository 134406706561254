import { Component } from '@angular/core';
import { PhcUrl } from '../../../../../app/models/interfaces/PhcData';
import { getPHCUrls } from '../../../../../app/utils/functions';

@Component({
  selector: 'phc-contribute-accordion',
  templateUrl: './contribute-accordion.component.html',
  styleUrls: ['./contribute-accordion.component.scss']
})
export class ContributeAccordionComponent {

  urls: PhcUrl = {}

  constructor() {
    this.urls = getPHCUrls()
  }

}
